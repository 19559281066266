import { Suspense, lazy } from "react"
import { RouteObject } from "react-router-dom"
import { ClaimProvider } from "../../context/v2/claimContext/claimContext"
import { SettingsProvider } from "../../context/v2/settingsContext"
import { SocialAuthProvider } from "../../context/v2/socialAuthContext"
import FundOrUnprotectedClaim from "./FundOrUnprotectedClaim"

const SettingsPage = lazy(() => import("../shared/Settings/SettingsPage"))
const TelegramAppFlow = lazy(() => import("./TelegramAppFlow"))
const TwitterFlow = lazy(() => import("./TwitterFlow"))

export const routes: RouteObject[] = [
  {
    id: "fund_or_claim",
    path: "/",
    element: (
      <Suspense fallback={<div></div>}>
        <FundOrUnprotectedClaim />
      </Suspense>
    ),
  },
  {
    id: "settings",
    path: "/settings",
    element: (
      <Suspense fallback={<div></div>}>
        <SettingsProvider>
          <SettingsPage />
        </SettingsProvider>
      </Suspense>
    ),
  },
  {
    id: "telegram",
    path: "/tg_claim",
    element: (
      <Suspense fallback={<div></div>}>
        <SocialAuthProvider>
          <ClaimProvider>
            <TelegramAppFlow />
          </ClaimProvider>
        </SocialAuthProvider>
      </Suspense>
    ),
  },
  {
    id: "twitter",
    path: "/x_claim",
    element: (
      <Suspense fallback={<div></div>}>
        <SocialAuthProvider>
          <ClaimProvider>
            <TwitterFlow />
          </ClaimProvider>
        </SocialAuthProvider>
      </Suspense>
    ),
  },
]
