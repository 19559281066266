import { Box, Button, Grid, Typography } from "@mui/material"
import React, { Suspense, lazy, useState } from "react"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { DesignSystem } from "../../../styles/theme"

enum FooterPage {
  NONE,
  ABOUT,
  PRIVACY,
  TERMS,
  IMPRINT,
}

const Footer: React.FC<{ designSystem: DesignSystem }> = ({ designSystem }) => {
  const [activePage, setActivePage] = useState<FooterPage>(FooterPage.NONE)
  const { utility, spacing } = designSystem

  const handleOpen = (page: FooterPage) => {
    setActivePage(page)
  }

  const handleClose = () => setActivePage(FooterPage.NONE)

  const Privacy = lazy(() => import("./FooterPages/Privacy/Privacy"))
  const Terms = lazy(() => import("./FooterPages/Terms/Terms"))
  const Imprint = lazy(() => import("./FooterPages/Imprint"))

  const visibleFooterPage = () => {
    switch (activePage) {
      case FooterPage.NONE:
        return <div />
      case FooterPage.PRIVACY:
        return <Privacy handleClose={handleClose} />
      case FooterPage.TERMS:
        return <Terms handleClose={handleClose} />
      case FooterPage.ABOUT:
        return <div />
      case FooterPage.IMPRINT:
        return <Imprint handleClose={handleClose} />
    }
  }

  return (
    <>
      <SwitchTransition>
        <CSSTransition
          key={`${activePage}`}
          classNames="fade"
          timeout={300}
          unmountOnExit
        >
          <Suspense fallback={<div></div>}>{visibleFooterPage()}</Suspense>
        </CSSTransition>
      </SwitchTransition>

      <Box
        display={"flex"}
        flexShrink={0}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        maxWidth={"600px"}
        alignSelf={"center"}
      >
        <Grid
          container
          style={{
            backgroundColor: "transparent",
            width: "100%",

            padding: `${spacing.sm} ${spacing.m}`,
          }}
        >
          <Grid
            item
            xs={3}
            onClick={() => window.open("https://gib.cash/about", "_blank")}
            style={{}}
          >
            <Button>
              <Typography
                textTransform={"none"}
                variant="label3"
                color={utility.utility1}
              >
                About
              </Typography>
            </Button>
          </Grid>

          <Grid
            item
            xs={3}
            onClick={(event) => {
              event.preventDefault()
              handleOpen(FooterPage.PRIVACY)
            }}
            style={{
              marginBottom: spacing.sm,
            }}
          >
            <Button>
              <Typography
                textTransform={"none"}
                variant="label3"
                color={utility.utility1}
              >
                Privacy
              </Typography>
            </Button>
          </Grid>
          <Grid
            item
            xs={3}
            onClick={(event) => {
              event.preventDefault()
              handleOpen(FooterPage.TERMS)
            }}
            style={{}}
          >
            <Button>
              <Typography
                textTransform={"none"}
                variant="label3"
                color={utility.utility1}
              >
                Terms
              </Typography>
            </Button>
          </Grid>
          <Grid
            item
            xs={3}
            onClick={(event) => {
              event.preventDefault()
              handleOpen(FooterPage.IMPRINT)
            }}
            style={{}}
          >
            <Button>
              <Typography
                textTransform={"none"}
                variant="label3"
                color={utility.utility1}
              >
                Imprint
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Footer
