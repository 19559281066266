import { useConnection, useWallet } from "@solana/wallet-adapter-react"
import { useWalletModal } from "@solana/wallet-adapter-react-ui"
import {
  Keypair,
  PublicKey,
  Transaction,
  VersionedTransaction,
} from "@solana/web3.js"
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import toast from "react-hot-toast"
import { solUsdcToken } from "../../../lib/constants"
import {
  GibWallet,
  PayloadSchema,
  explainError,
  getSolKeypair,
  isLoggedIn,
  isPassKeyError,
  authenticate as passkeyLogin,
  logout as passkeyLogout,
  registerPasskey as passkeySignup,
} from "../../../lib/passkeys"
import {
  FullBalanceResponse,
  getCloseAccountTx,
  getFullAccountBalance,
  sendTransaction as solSendGib,
} from "../../../lib/requests"
import { Deposit, Token } from "../../../lib/types"
import { IconType } from "../../../sections/shared/SvgIcon"
import { ClaimBalanceFetch } from "../claimContext/types"
import { useLocaleContext } from "../localeContext"
import {
  awaitTxConfirmation,
  getSolDeposit,
  getSolDeposits,
  getSolTokenBalance,
} from "./requests"
import {
  BaseSource,
  SolExtensionActions,
  SolPasskeyActions,
  SourceOrigin,
  defaultSources,
  isConnectedSource,
  isDisconnectedSource,
  isSourceTypeSol,
} from "./types"

type SourceContextType = {
  sources: BaseSource[]
  selectedSource: BaseSource | null
  selectSource: (source: SourceOrigin) => void
  createAndSelectPasskeySource: (select: boolean) => Promise<void>
  refreshSourceBalances: () => void
}

const SourceContext = createContext<SourceContextType | null>(null)

const SourceProvider: React.FC<{ children: ReactNode }> =
  function SourceProvider({ children }) {
    // Inject SOL wallet extension behaviour
    const { disconnect, publicKey, connected, signTransaction, wallet } =
      useWallet()
    const { connection } = useConnection()
    const { setVisible: walletConnect } = useWalletModal()
    const { locale } = useLocaleContext()

    const [sources, setSources] = useState<BaseSource[]>(defaultSources)

    const [selectedSource, setSelectedSource] = useState<SourceOrigin | null>(
      null
    )

    // Handles retrieving token balances for sources
    const fetchSourceBalance = useCallback(
      async (publicKey: PublicKey, token: Token) => {
        let updatedSources = sources
        let sourceIndex = updatedSources.findIndex(
          (source) => source.publicKey === publicKey
        )
        if (sourceIndex !== -1) {
          const source = updatedSources[sourceIndex]
          if (isSourceTypeSol(source)) {
            getSolTokenBalance(connection, source.publicKey, token)
              .then((balance) => {
                source.tokenBalance = balance
                updatedSources[sourceIndex] = source
              })
              .then(() => {
                setSources([...updatedSources])
                if (source.origin === selectedSource) {
                  setSelectedSource(source.origin)
                }
              })
          }
        }
      },
      [connection, selectedSource, sources]
    )

    const createAndSelectPasskeySource = useCallback(
      async (selectSource: boolean) => {
        let updatedSources = sources
        let passkeySource = sources.find(
          (source) => source.origin === SourceOrigin.SOL_WALLET_PASSKEY
        )

        if (passkeySource) {
          return passkeySignup()
            .then((response) => {
              const { solKey } = response
              const passkeySolKey = new PublicKey(solKey)

              const solPasskeySource = updatedSources.findIndex(
                (source) => source.origin === SourceOrigin.SOL_WALLET_PASSKEY
              )

              if (passkeySolKey && solPasskeySource !== -1) {
                updatedSources[solPasskeySource].connected = true
                updatedSources[solPasskeySource].publicKey = passkeySolKey
                updatedSources[solPasskeySource].metaData = {
                  name: "Gib Wallet",
                  logoUri: IconType.GibCashGreen,
                }
                setSources([...updatedSources])
                selectSource &&
                  setSelectedSource(SourceOrigin.SOL_WALLET_PASSKEY)
                fetchSourceBalance(
                  passkeySolKey,
                  updatedSources[solPasskeySource].selectedToken
                )
                return Promise.resolve()
              }
            })
            .catch((err) => {
              if (isPassKeyError(err)) {
                let message = explainError(err, locale)
                toast(message, {
                  position: "top-center",
                  icon: "⚠️",
                })
              }
            })
        }
      },
      [locale, fetchSourceBalance, sources]
    )

    const refreshSourceBalances = useCallback(() => {
      for (let source of sources) {
        if (isConnectedSource(source)) {
          fetchSourceBalance(source.publicKey, source.selectedToken)
        }
      }
    }, [fetchSourceBalance, sources])

    const disconnectSource = useCallback(
      async (sourceOrigin: SourceOrigin) => {
        const updatedSources = sources
        const sourceIndex = updatedSources.findIndex(
          (source) => source.origin === sourceOrigin
        )

        const source = updatedSources[sourceIndex]

        if (source) {
          source.connected = false
          source.publicKey = null
          source.metaData = null
          source.action = null
          updatedSources[sourceIndex] = source
          setSources([...updatedSources])
        }
      },
      [sources]
    )

    // Handles selecting a source for use in fund/claim flows
    const selectSource = useCallback(
      (sourceTarget: SourceOrigin) => {
        const index = sources.findIndex(
          (source) => source.origin === sourceTarget
        )
        if (index !== -1) {
          const source = sources[index]
          if (isConnectedSource(source)) {
            fetchSourceBalance(source.publicKey, source.selectedToken)
            setSelectedSource(source.origin)
          }
        } else {
          setSelectedSource(null)
        }
      },
      [fetchSourceBalance, sources]
    )

    // These actions are tied directly to SOL connected wallet extension
    // neccessary to trigger extension interface
    const walletActions = useCallback((): SolExtensionActions => {
      return {
        disconnect: (): Promise<void> =>
          disconnect().then(() => {
            disconnectSource(SourceOrigin.SOL_WALLET_EXTENSION)
          }),
        refetchBalance: (pubkey: PublicKey) => {
          fetchSourceBalance(pubkey, solUsdcToken)
        },
        select: (): void => selectSource(SourceOrigin.SOL_WALLET_EXTENSION),
        signTx: (tx: Transaction): Promise<Transaction> =>
          signTransaction
            ? signTransaction(tx)
            : Promise.reject("notConnected!"),
        sendTx: (
          tx: Transaction | VersionedTransaction,
          withConfirmation: boolean = false
        ): Promise<string> =>
          solSendGib(tx).then((id) => {
            return withConfirmation
              ? awaitTxConfirmation(connection, id)
              : Promise.resolve(id)
          }),
        getDeposits: (
          senderPubkey: PublicKey,
          tokens: Token[]
        ): Promise<Deposit[]> => getSolDeposits(senderPubkey, tokens),
        getDeposit: (
          sessionKeypair: Keypair,
          token: Token
        ): Promise<ClaimBalanceFetch["payload"]> =>
          getSolDeposit(sessionKeypair.publicKey, token, connection),
      }
    }, [
      disconnect,
      disconnectSource,
      fetchSourceBalance,
      selectSource,
      signTransaction,
      connection,
    ])

    // These actions are tied directly to SOL connected wallet extension
    const solPasskeyActions = useCallback((): SolPasskeyActions => {
      return {
        disconnect: (): Promise<void> => {
          passkeyLogout()
          disconnectSource(SourceOrigin.SOL_WALLET_PASSKEY)
          return Promise.resolve()
        },
        refetchBalance: (pubkey: PublicKey) => {
          fetchSourceBalance(pubkey, solUsdcToken)
        },
        select: (): void => selectSource(SourceOrigin.SOL_WALLET_PASSKEY),
        signTx: (tx: Transaction, keypair: Keypair): Promise<Transaction> => {
          let incomingTx = tx
          incomingTx.partialSign(keypair)
          return Promise.resolve(incomingTx)
        },
        sendTx: (
          tx: Transaction | VersionedTransaction,
          withConfirmation: boolean = false
        ): Promise<string> =>
          solSendGib(tx).then((id) => {
            return withConfirmation
              ? awaitTxConfirmation(connection, id)
              : Promise.resolve(id)
          }),
        getSolKeypair: (): Promise<Keypair> => {
          return getSolKeypair()
        },
        getDeposits: (
          senderPubkey: PublicKey,
          tokens: Token[]
        ): Promise<Deposit[]> => getSolDeposits(senderPubkey, tokens),
        getDeposit: (
          sessionKeypair: Keypair,
          token: Token
        ): Promise<ClaimBalanceFetch["payload"]> =>
          getSolDeposit(sessionKeypair.publicKey, token, connection),

        getAccountClosureTx: (
          accountAddr: PublicKey,
          tokenMint: PublicKey,
          recipient: PublicKey
        ): Promise<Transaction> =>
          getCloseAccountTx(accountAddr, tokenMint, recipient),
        getFullAccountBalance: (
          accountAddr: PublicKey
        ): Promise<FullBalanceResponse> => getFullAccountBalance(accountAddr),
      }
    }, [connection, disconnectSource, fetchSourceBalance, selectSource])

    // whenever a source is initially connected we fetch the balance
    useEffect(() => {
      sources.forEach((source) => {
        if (isConnectedSource(source) && !source.tokenBalance) {
          fetchSourceBalance(source.publicKey, source.selectedToken)
        } else {
          return
        }
      })
    }, [fetchSourceBalance, sources])

    // block injects connect actions from hooks into DisconnectedSource(s)
    useEffect(() => {
      let updatedSources = sources
      updatedSources.forEach((source, index) => {
        if (isDisconnectedSource(source) && source.action === null) {
          switch (source.origin) {
            case SourceOrigin.SOL_WALLET_EXTENSION: {
              updatedSources[index].action = {
                connect: () => Promise.resolve(walletConnect(true)),
              }
              setSources([...updatedSources])
              break
            }
            case SourceOrigin.SOL_WALLET_PASSKEY:
              updatedSources[index].action = {
                signIn: async (
                  pubkeyMatches?: PublicKey
                ): Promise<GibWallet> => {
                  return passkeyLogin(pubkeyMatches)
                    .then((response) => {
                      const { sol } = response
                      const passkeySolKey = new PublicKey(sol.publicKey)
                      const solPasskeySource = updatedSources.findIndex(
                        (source) =>
                          source.origin === SourceOrigin.SOL_WALLET_PASSKEY
                      )

                      if (passkeySolKey && solPasskeySource !== -1) {
                        updatedSources[solPasskeySource].connected = true
                        updatedSources[solPasskeySource].publicKey =
                          passkeySolKey
                        updatedSources[solPasskeySource].metaData = {
                          name: "Gib Wallet",
                          logoUri: IconType.GibCashGreen,
                        }
                        setSources([...updatedSources])
                      }
                      return Promise.resolve(response)
                    })
                    .catch((err) => {
                      if (isPassKeyError(err)) {
                        let message = explainError(err, locale)
                        toast(message, {
                          position: "top-center",
                          icon: "⚠️",
                        })
                      }
                      return Promise.reject(explainError(err, locale))
                    })
                },
                signUp: async (): Promise<PayloadSchema> => {
                  return passkeySignup()
                    .then((response) => {
                      return Promise.resolve(response)
                    })
                    .catch((err) => {
                      if (isPassKeyError(err)) {
                        let message = explainError(err, locale)
                        toast(message, {
                          position: "top-center",
                          icon: "⚠️",
                        })
                      }
                      return Promise.reject(explainError(err, locale))
                    })
                },
              }
              break
          }
        }
      })
    }, [locale, walletConnect, sources])

    // block checks for connect hooks from extensions and transforms DisconnectSource to connected Sources
    useEffect(() => {
      const updatedSources = sources

      // HANDLE SOL PASSKEY CONNECTION EVENTS
      let solPasskeySource = updatedSources.findIndex(
        (source) => source.origin === SourceOrigin.SOL_WALLET_PASSKEY
      )
      let passkeyKeys = isLoggedIn()
      if (updatedSources[solPasskeySource] && passkeyKeys) {
        updatedSources[solPasskeySource].action = solPasskeyActions()
        const passkeyDataNeeded =
          updatedSources[solPasskeySource].connected === false &&
          updatedSources[solPasskeySource].publicKey === null
        if (passkeyDataNeeded) {
          let passkeySolKey = new PublicKey(passkeyKeys.solKey)
          updatedSources[solPasskeySource].connected = true
          updatedSources[solPasskeySource].publicKey = passkeySolKey
          updatedSources[solPasskeySource].metaData = {
            name: "Gib Wallet",
            logoUri: IconType.GibCashGreen,
          }
          setSources([...updatedSources])
        }
      }

      // HANDLE SOL EXTENSION CONNECT EVENTS
      const walletSource = updatedSources.findIndex(
        (source) => source.origin === SourceOrigin.SOL_WALLET_EXTENSION
      )
      // Assign the appropriate actions from the SOL wallet hooks
      if (updatedSources[walletSource]) {
        // assign available functions on mount
        // we'll recheck this once a wallet connects
        // (signTx function is not available until connection)
        // We need the Solana Adapter to expose the needed data
        const extensionDataAvailable = connected && publicKey && wallet
        // We need to ensure the source requires the exposed data
        const extensionDataNeeded =
          updatedSources[walletSource].connected === false &&
          updatedSources[walletSource].publicKey === null
        if (extensionDataAvailable && extensionDataNeeded) {
          // We rely on solana extension hooks to expose actions on wallet actions
          updatedSources[walletSource].action = walletActions()
          // Finally we assign the required attributes
          updatedSources[walletSource].connected = connected
          updatedSources[walletSource].publicKey = publicKey
          updatedSources[walletSource].metaData = {
            name: wallet.adapter.name,
            logoUri: wallet.adapter.icon,
          }
          setSources([...updatedSources])
        }
      }
    }, [
      solPasskeyActions,
      wallet,
      walletActions,
      connected,
      publicKey,
      sources,
    ])

    return (
      <SourceContext.Provider
        value={{
          refreshSourceBalances,
          sources,
          selectSource,
          createAndSelectPasskeySource,
          selectedSource: useMemo(() => {
            let source = sources.find(
              (source) =>
                isConnectedSource(source) && source.origin === selectedSource
            )
            return source ? source : null
          }, [selectedSource, sources]),
        }}
      >
        {children}
      </SourceContext.Provider>
    )
  }
function useSourceContext(): SourceContextType {
  const context = useContext(SourceContext)

  if (!context) {
    throw new Error("Missing SourceContext")
  }

  return context
}

export { SourceProvider, useSourceContext }
