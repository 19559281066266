import * as bip39 from "@scure/bip39"
import { wordlist } from "@scure/bip39/wordlists/english"
import { Keypair, PublicKey } from "@solana/web3.js"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import { RouteObject, matchRoutes, useLocation } from "react-router-dom"
import { SocialAccount, SocialPlatform, TimeInSeconds } from "../../lib/types"
import {
  SelectedChain,
  decodeWithConfig,
  encodeWithConfig,
  initializeDappConfig,
} from "../../lib/utils"
import { routes } from "../../sections/body/routes"

export type SuccessState = {
  expirationTime: TimeInSeconds
  hasPassword: boolean
  socialAccount: SocialAccount | null
}

type ChainAddressType<C extends SelectedChain, A> = {
  address: A
  chain: C
}

export type SolAddress = ChainAddressType<SelectedChain.SOLANA, PublicKey>

export type ChainAddress = SolAddress

export type SessionContextType = {
  sessionId: string | null
  sessionSolanaKeypair: Keypair | null
  sessionChecked: boolean
  handleSetSession: (password: string, socialAccount?: SocialAccount) => void
  handlePasswordSubmit: (password: string) => void
  isPasswordCorrect: boolean | null
  setIsPasswordCorrect: (isPasswordCorrect: boolean | null) => void
  sessionPubkey: PublicKey | null
  setSessionPubkey: (pubkey: PublicKey | null) => void
  setSessionId: (sessionId: string) => void
  socialVerificationPlatform: SocialPlatform | null
  successState: SuccessState | null
  onSuccess: (event: SuccessState | null) => void
  currentRoute: RouteObject | null
  shareRoute: string | null
}

const SessionContext = createContext<SessionContextType | null>(null)

// SessionProvider is concerned with creating or deriving seed phrases
// this is dependent on route and critical to the escrowAccount
const SessionProvider: React.FC<{ children: React.ReactNode }> =
  function SessionProvider({ children }) {
    const [sessionSolanaKeypair, setSessionSolanaKeypair] =
      useState<Keypair | null>(null)
    const [sessionPubkey, setSessionPubkey] = useState<PublicKey | null>(null)

    const location = useLocation()
    const [sessionId, setSessionId] = useState<string | null>(null)

    const currentRoute = useMemo(() => {
      const match = matchRoutes(routes, location)
      if (match && match.length >= 1 && match !== null) {
        return match[0].route
      } else {
        return null
      }
    }, [location])

    const shareRoute = useMemo(() => {
      if (sessionId) {
        if (window.location.origin && window.location.origin.length > 1) {
          return `${window.location.origin}/#${sessionId}`
        } else {
          return `https://gib.cash/#${sessionId}`
        }
      } else {
        return null
      }
    }, [sessionId])

    const routeSessionId = useMemo(() => {
      if (currentRoute?.id === "fund_or_claim" && location.hash) {
        return location.hash.substring(1)
      }
      return null
    }, [currentRoute, location])

    const [successState, setSuccessState] = useState<SuccessState | null>(null)
    const [socialVerificationPlatform, setSocialVerificationPlatform] =
      useState<SocialPlatform | null>(null)

    const [sessionChecked, setSessionChecked] = useState<boolean>(false)
    const [mnemonic, setMnemonic] = useState<string | null>(null)
    const [passwordCheck, setPasswordCheck] = useState<Uint8Array | null>(null)
    const [isPasswordCorrect, setIsPasswordCorrect] = useState<boolean | null>(
      null
    )

    useEffect(() => {
      if (sessionSolanaKeypair) {
        setSessionPubkey(sessionSolanaKeypair.publicKey)
      }
    }, [sessionSolanaKeypair])

    // if we are in claim flow, the sessionId will be pulled off of the url
    useEffect(() => {
      if (!sessionChecked && !!routeSessionId) {
        setSessionId(routeSessionId)
        const { seedWordIndexArr, config, passwordCheck } =
          decodeWithConfig(routeSessionId)
        const WordIndexArr = seedWordIndexArr.slice(0, 12)
        const mnemonic = WordIndexArr.map((index) => wordlist[index]).join(" ")
        setMnemonic(mnemonic)
        setPasswordCheck(passwordCheck)
        if (config.isPasswordProtected) {
          setSessionSolanaKeypair(null)
        } else {
          const seed = bip39.mnemonicToSeedSync(mnemonic, "")
          let solKeypair = Keypair.fromSeed(seed.slice(0, 32))
          setSessionSolanaKeypair(solKeypair)
        }

        // attention: the config decodes a default, even if the verification is not required
        setSocialVerificationPlatform(config.social)

        setSessionChecked(true)
      }
    }, [sessionChecked, routeSessionId])

    const handlePasswordSubmit = useCallback(
      async (password: string) => {
        if (mnemonic) {
          const seed = bip39.mnemonicToSeedSync(mnemonic, password)
          const hash = await window.crypto.subtle.digest("SHA-256", seed)
          let isEqual = true
          const hashArray = new Uint8Array(hash.slice(0, 3))

          if (passwordCheck) {
            for (let i = 0; i < passwordCheck.length; i++) {
              if (passwordCheck[i] !== hashArray[i]) {
                isEqual = false
                break
              }
            }
            if (!isEqual) {
              setIsPasswordCorrect(false)
              return
            } else {
              setIsPasswordCorrect(true)
            }
          }

          let solKeypair = Keypair.fromSeed(seed.slice(0, 32))
          setSessionSolanaKeypair(solKeypair)
        }
      },
      [mnemonic, passwordCheck]
    )

    const handleSetSession = useCallback(
      async (password: string, account?: SocialAccount) => {
        const passwordProtected = password.length > 0
        const mnemonic = bip39.generateMnemonic(wordlist)
        const phraseToWordlistIndex = mnemonic
          .split(" ")
          .map((phraseWord) =>
            wordlist.findIndex((word) => phraseWord === word)
          )

        const seed = bip39.mnemonicToSeedSync(mnemonic, password)
        let passwordCheck
        if (passwordProtected) {
          const hash = await window.crypto.subtle.digest("SHA-256", seed)
          passwordCheck = new Uint8Array(hash.slice(0, 3))
        }

        const newSession = encodeWithConfig(
          phraseToWordlistIndex,
          initializeDappConfig(
            passwordProtected,
            account?.platform || SocialPlatform.TELEGRAM
          ),
          passwordCheck
        )
        setSessionId(newSession)
        const keypair = Keypair.fromSeed(seed.slice(0, 32))
        setSessionSolanaKeypair(keypair)
      },
      []
    )

    return (
      <SessionContext.Provider
        value={{
          handlePasswordSubmit,
          handleSetSession,
          isPasswordCorrect,
          onSuccess: setSuccessState,
          sessionChecked,
          sessionId,
          sessionPubkey,
          sessionSolanaKeypair,
          setIsPasswordCorrect,
          setSessionId,
          setSessionPubkey,
          socialVerificationPlatform,
          successState,
          shareRoute,
          currentRoute,
        }}
      >
        {children}
      </SessionContext.Provider>
    )
  }

function useSessionContext(): SessionContextType {
  const context = useContext(SessionContext)

  if (!context) {
    throw new Error("Missing SessionContext")
  }

  return context
}

export { SessionProvider, useSessionContext }
