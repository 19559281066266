import { PublicKey } from "@solana/web3.js"
import { DepositActions } from "../context/v2/settingsContext"
import { IconType } from "../sections/shared/SvgIcon"
import { SelectedChain } from "./utils"

export enum TimeInSeconds {
  TEN_MINUTES = 600, // 10 * 60
  TWENTY_FOUR_HOURS = 86400, // 24 * 60 * 60
  THREE_DAYS = 259200, // 3 * 24 * 60 * 60
  ONE_WEEK = 604800, // 7 * 24 * 60 * 60
}

export enum FundingSource {
  SOL = "Solana Wallet",
  PASSKEY_SOL = "Embedded Solana",
  CUSTOM = "Custom Address",
}

type TClaimInitData<C extends SelectedChain, T> = {
  chain: C
  code: string
  oauthToken?: string
  oauthTokenSecret?: string
} & T

type SolClaimInitData = TClaimInitData<
  SelectedChain.SOLANA,
  {
    socialSig: number[]
    linkPubkey: PublicKey
  }
>

export type ClaimInitData = SolClaimInitData

export interface Token {
  name: string
  decimals: number
  addr: string
  symbol: string
  icon: IconType
}

export interface Deposit {
  sender: PublicKey
  socialId: null | string
  sessionPubkey: PublicKey
  revertTs: number
  amount: {
    raw: bigint
    display: string
  }
  escrowToken: Token
  status: DepositActions
}

export interface GibData {
  Initialized?: {
    sender: number[]
    social_id: null | string
    link_pubkey: number[]
    revert_ts: number
    amount: number
    escrow_acc: number[]
    escrow_token: number[]
    pda_bump: number
    pda_seed: number[]
  }
}

export interface DepositResponse {
  sender: number[]
  social_id: null | string
  link_pubkey: number[]
  revert_ts: number
  amount: bigint
  escrow_acc: number[]
  escrow_token: number[]
  pda_bump: number
  pda_seed: number[]
}

export type SocialClaimParams = {
  code: string
  linkOwnershipVerifier: number[]
}

export type RegularClaimParams = {
  receiver: PublicKey
}

export type ClaimResponse = {
  tx: string
  tx_sig: string
  payout_amount: number
}

export type ClaimParams = RegularClaimParams | SocialClaimParams

export type SocialPrefix = "tg" | "x"

export enum SocialPlatform {
  TELEGRAM = 0,
  X = 1,
}

export type SocialAccount = {
  platform: SocialPlatform
  handle: string
}

// For further reading: https://core.telegram.org/bots/webapps#validating-data-received-via-the-mini-app
export type TelegramAppData = {
  dataCheckString: string
  hash: string
  originalData: URLSearchParams
}

export type TelegramUser = {
  username: string
}

export type VerifiedTelegramData = {
  user: TelegramUser
  recipient?: string
}

export type oAuthResponseData = {
  oauth_token: string
  oauth_token_secret: string
}
