import {
  FundActionKind,
  FundActions,
  FundProviderState,
  FundState,
  VisualState,
  fundProviderDefaultState,
} from "./types"

export function fundReducer(
  state: FundProviderState,
  action: FundActions
): FundProviderState {
  switch (action.type) {
    case FundActionKind.FUND_FORM_SUBMIT: {
      return {
        ...state,
        ...action.payload,
        fundState: FundState.FORM_SUBMITTED,
      }
    }
    case FundActionKind.FUND_TX_INIT: {
      return {
        ...state,
        fundState: FundState.TX_CREATED,
        tx: action.payload.tx,
      }
    }
    case FundActionKind.FUND_TX_SIGN: {
      return {
        ...state,
        tx: action.payload.tx,
        fundState: FundState.TX_SIGNED,
        visualState: VisualState.PROCESSING,
      }
    }
    case FundActionKind.FUND_TX_SEND: {
      return {
        ...state,
        txId: action.payload.txId,
        fundState: FundState.TX_SENT,
      }
    }
    case FundActionKind.FUND_TX_ERROR:
    case FundActionKind.FUND_TX_RESET: {
      return {
        ...fundProviderDefaultState,
      }
    }
    case FundActionKind.VISUAL_TRANSITION: {
      return {
        ...state,
        ...action.payload,
      }
    }
  }
}
