import { FC, Fragment, useEffect, useRef, useState } from "react"
import bgCyan from "../assets/images/bgCyan.webp"
import videoB from "../assets/videos/backgroundVideo.mp4"

const VideoBackground: FC = () => {
  const [shouldShowStaticBackground, setShouldShowStaticBackground] =
    useState(true)
  const playerRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.play().catch((err) => {
        // we cannot play the video for some reason, this could be some ios low-energy and low permissions problem.
        // to not show a play button that disturbs people, we don't show the video but a static background
        setShouldShowStaticBackground(true)
      })
    }
  }, [playerRef])

  return (
    <Fragment>
      <img
        src={bgCyan}
        alt={"background"}
        style={{
          position: "fixed",
          left: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
          zIndex: "-1",
          objectFit: "cover",
          overflow: "hidden",
          transition: "opacity 2.5s ease-out",
          opacity: shouldShowStaticBackground ? "1" : "0",
        }}
      />

      <video
        ref={playerRef}
        style={{
          position: "fixed",
          left: 0,
          bottom: 0,
          width: "100%",
          height: "100%",
          zIndex: "-1",
          objectFit: "cover",
          overflow: "hidden",
          transition: "opacity 2.5s ease-in",
          opacity: shouldShowStaticBackground ? "0" : "1",
        }}
        preload="none"
        onLoadedData={() => {
          setShouldShowStaticBackground(false)
        }}
        loop
        muted
        playsInline
        // we don't use autoplay to be able to catch any error trying to play the video, see above
      >
        <source src={videoB} type="video/mp4" />
      </video>
    </Fragment>
  )
}

export default VideoBackground
