import { IconType } from "../sections/shared/SvgIcon"
import { Token } from "./types"

const EXTERNAL_GIB_URL =
  process.env.REACT_APP_GIB_URL || "https://gib.external.dev.unstp.io"

export const GIB_URL =
  process.env.REACT_APP_DEV === "true"
    ? "http://127.0.0.1:9000"
    : EXTERNAL_GIB_URL

export const X_VERIFY_ENDPOINT = `${GIB_URL}/x_verify`

export const solUsdcToken: Token = {
  name: "USD Coin",
  addr: "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v",
  icon: IconType.UsdcToken,
  symbol: "USDC",
  decimals: 6,
}

export const MAX_PASSKEY_CLAIM_AMOUNT = 5
export const MAX_PASSKEY_WALLET_AMOUNT = 10

export const TELEGRAM_BOT_NAME =
  process.env.REACT_APP_TELEGRAM_BOT_NAME || "GibCashBot"
export const TELEGRAM_APP_NAME =
  process.env.REACT_APP_TELEGRAM_APP_NAME || "GibCash"
