import { getAssociatedTokenAddressSync, getMint } from "@solana/spl-token"
import { Connection, PublicKey } from "@solana/web3.js"
import { getDeposit, getDeposits } from "../../../lib/requests"
import { Deposit, Token } from "../../../lib/types"
import { amountMultiplier, formatAmount } from "../../../lib/utils"
import { ClaimBalanceFetch } from "../claimContext/types"
import { DepositActions } from "../settingsContext"
import { TokenBalance } from "./types"

export const getSolTokenBalance = async (
  connection: Connection,
  publicKey: PublicKey,
  token: Token
): Promise<TokenBalance> => {
  let tokenAccAddr = getAssociatedTokenAddressSync(
    new PublicKey(token.addr),
    publicKey
  )

  return connection
    .getTokenAccountBalance(tokenAccAddr, "confirmed")
    .then((response) => {
      const { amount } = response.value
      return Promise.resolve({
        rawAmount: BigInt(amount),
        formattedAmount: formatAmount(Number(amount), token.decimals),
      })
    })
    .catch((_) => {
      return Promise.resolve({
        rawAmount: BigInt(0),
        formattedAmount: "0.00",
      })
    })
}

export const getSolDeposit = async (
  ownerPubkey: PublicKey,
  token: Token,
  connection: Connection
): Promise<ClaimBalanceFetch["payload"]> => {
  const res = await getDeposit(ownerPubkey)

  if (res?.Initialized) {
    const { social_id, revert_ts, amount, sender } = res.Initialized
    const senderPubkey = new PublicKey(new Uint8Array(sender)).toString()

    let tokenMint = await getMint(connection, new PublicKey(token.addr))
    let expirationTime = revert_ts
    let claimableAmount = BigInt(amount)
    let displayAmount = (amount / amountMultiplier(tokenMint.decimals)).toFixed(
      2
    )

    return Promise.resolve({
      amount: claimableAmount,
      displayAmount,
      expirationTime,
      socialId: social_id,
      verified: social_id ? !social_id : null,
      senderPubkey,
    })
  } else {
    return Promise.reject(new Error("Deposit not initialized."))
  }
}

export const getSolDeposits = async (
  senderPubkey: PublicKey,
  supportedTokens: Token[]
): Promise<Deposit[]> => {
  return getDeposits(senderPubkey)
    .then((deposits) => {
      return deposits
        .filter((deposit) =>
          supportedTokens.some(
            (token) =>
              new PublicKey(deposit.escrow_token).toString() === token.addr
          )
        )
        .flatMap((deposit) => {
          let mint = new PublicKey(deposit.escrow_token)
          let token = supportedTokens.find(
            (token) => token.addr === mint.toString()
          )
          if (!token) {
            return []
          }
          return {
            sender: new PublicKey(deposit.sender),
            socialId: deposit.social_id,
            revertTs: deposit.revert_ts,
            sessionPubkey: new PublicKey(deposit.link_pubkey),
            amount: {
              raw: deposit.amount,
              display: (
                Number(deposit.amount) / amountMultiplier(token!.decimals)
              ).toFixed(2),
            },
            escrowToken: token!,
            status:
              deposit.revert_ts < Date.now() / 1000
                ? DepositActions.REVERT
                : DepositActions.CANCEL,
          } as Deposit
        })
    })
    .catch((_) => {
      return Promise.resolve([])
    })
}

export async function awaitTxConfirmation(
  connection: Connection,
  txId: string
): Promise<string> {
  const checkIntervalMs = 200

  const delay = (ms: number) => new Promise((res) => setTimeout(res, ms))

  const getSignatureStatus = async (): Promise<string> => {
    const status = await connection.getSignatureStatus(txId)
    if (status.value?.confirmationStatus === "confirmed") {
      return txId
    } else {
      await delay(checkIntervalMs)
      return getSignatureStatus()
    }
  }

  return getSignatureStatus()
}
