import { ThemeProvider } from "@emotion/react"
import { CssBaseline } from "@mui/material"
import { BrowserRouter } from "react-router-dom"
import { LocaleProvider } from "./context/v2/localeContext"
import { SessionProvider } from "./context/v2/sessionContext"
import SolanaWalletProvider from "./context/v2/solanaWalletContext"
import { SourceProvider } from "./context/v2/sourceContext/sourceContext"
import AppBody from "./sections/AppBody"
import { theme } from "./styles/theme"

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocaleProvider>
        <BrowserRouter>
          <SolanaWalletProvider>
            <SourceProvider>
              <SessionProvider>
                <AppBody />
              </SessionProvider>
            </SourceProvider>
          </SolanaWalletProvider>
        </BrowserRouter>
      </LocaleProvider>
    </ThemeProvider>
  )
}

export default App
