import { useTheme } from "@mui/material"
import { Suspense, lazy } from "react"
import { useLocation } from "react-router-dom"
import { ClaimProvider } from "../../context/v2/claimContext/claimContext"
import { FundProvider } from "../../context/v2/fundContext/fundContext"
import { useLocaleContext } from "../../context/v2/localeContext"
import { SocialAuthProvider } from "../../context/v2/socialAuthContext"

const FundOrUnprotectedClaim = () => {
  const location = useLocation()

  const {
    palette: { designSystem },
  } = useTheme()
  const { locale } = useLocaleContext()

  const isClaim = Boolean(location.hash)

  const FundFlow = lazy(() => import("./fundFlow/FundFlow"))
  const ClaimFlow = lazy(() => import("./claimFlow/ClaimFlow"))

  if (!location) {
    return null
  }
  if (isClaim) {
    return (
      <Suspense fallback={<div></div>}>
        <SocialAuthProvider>
          <ClaimProvider>
            <ClaimFlow />
          </ClaimProvider>
        </SocialAuthProvider>
      </Suspense>
    )
  } else {
    return (
      <Suspense fallback={<div></div>}>
        <FundProvider>
          <FundFlow locale={locale} designSystem={designSystem} />
        </FundProvider>
      </Suspense>
    )
  }
}

export default FundOrUnprotectedClaim
