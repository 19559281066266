const tastes: string[] = [
  "Bitter",
  "Chalky",
  "Chewy",
  "Creamy",
  "Crispy",
  "Crunchy",
  "Dry",
  "Greasy",
  "Gritty",
  "Mild",
  "Moist",
  "Oily",
  "Plain",
  "Salty",
  "Savory",
  "Sour",
  "Spicy",
  "Sweet",
  "Tangy",
  "Tart",
  "Zesty",
]

const cheeses: string[] = [
  "Asadero",
  "Asiago",
  "Blue",
  "Brick",
  "Brie",
  "Burrata",
  "Butterkase",
  "Camembert",
  "Cheddar",
  "Colby",
  "Cotija",
  "Edam",
  "Emmentaler",
  "Feta",
  "Fontina",
  "Gorgonzola",
  "Gouda",
  "Havarti",
  "Kasseri",
  "Limburger",
  "Mascarpone",
  "Mozzarella",
  "Muenster",
  "Parmesan",
  "Pepato",
  "Provolone",
  "Ricotta",
  "Romano",
  "Swiss",
]

export const generatePasskeyName = (): string => {
  let taste = tastes[Math.floor(Math.random() * tastes.length)]
  let cheese = cheeses[Math.floor(Math.random() * cheeses.length)]
  return `${taste} ${cheese}`
}
