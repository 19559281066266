import { useConnection } from "@solana/wallet-adapter-react"
import { Keypair, PublicKey, Signer } from "@solana/web3.js"
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react"
import { solUsdcToken } from "../../../lib/constants"
import {
  claimTx,
  deserializeTx,
  getVerificationMessage,
  sendTransaction,
  signVerificationMessage,
} from "../../../lib/requests"
import { ClaimParams } from "../../../lib/types"
import { useSessionContext } from "../sessionContext"
import { useSocialAuthContext } from "../socialAuthContext"
import { getSolDeposit } from "../sourceContext/requests"
import { useSourceContext } from "../sourceContext/sourceContext"
import { isSourceTypeSol } from "../sourceContext/types"
import { claimReducer } from "./reducer"
import {
  ClaimActionKind,
  ClaimActions,
  ClaimProviderState,
  ClaimState,
  ClaimVisualState,
  claimProviderDefaultState,
} from "./types"

type ClaimContextType = {
  state: ClaimProviderState
  dispatch: (action: ClaimActions) => void
  handleClaim: () => void
  socialSignature: number[] | null
  setSocialSignature: (sig: number[] | null) => void
  customReceiverPublickey: PublicKey | null
  setCustomReceiverPublicKey: (address: PublicKey | null) => void
  shouldClaimToCustomReceiverPublicKey: boolean
  setShouldClaimToCustomReceiverPublicKey: (shouldClaim: boolean) => void
}

const ClaimContext = createContext<ClaimContextType | null>(null)

const ClaimProvider: React.FC<{ children: React.ReactNode }> = React.memo(
  function ClaimProvider({ children }) {
    const { sessionPubkey, sessionSolanaKeypair } = useSessionContext()
    const { selectedSource } = useSourceContext()
    const { connection } = useConnection()
    const { verifSignature, startVerification, verifCode } =
      useSocialAuthContext()

    const [requestInFlight, setRequestInFlight] = useState<boolean>(false)

    const [customReceiverPublickey, setCustomReceiverPublicKey] =
      useState<PublicKey | null>(null)

    const [
      shouldClaimToCustomReceiverPublicKey,
      setShouldClaimToCustomReceiverPublicKey,
    ] = useState<boolean>(false)

    const [state, dispatch] = useReducer(
      claimReducer,
      claimProviderDefaultState
    )

    // Setting the signature which is used to verify the link ownership on the
    // claiming transaction.
    //
    // Open Claim Flow (w/o Social 2FA):
    // When the link is opened inside the browser and no social 2FA is configured,
    // the seed phrase is extracted from the URL and the transaction can be signed
    // with the seed phrase
    //
    // Telegram Claim Flow:
    // For the Telegram claim flow, the Telegram miniapp is initialized with
    // a parameter which can not be protected against becoming known to the
    // server. (not #-protected)
    // Because we don't want to reveal the seed phrase to neither our backend
    // nor the GibCash backend, the Telegram app is receiving the signature of a
    // pre-signed message instead.
    const [socialSignature, setSocialSignature] = useState<number[] | null>(
      null
    )

    useEffect(() => {
      if (!!state.socialId && sessionSolanaKeypair) {
        const signClaim = async (signer: Signer, recipient: PublicKey) => {
          const message = await getVerificationMessage(
            signer.publicKey,
            recipient
          )
          const signature = signVerificationMessage(signer, message)
          setSocialSignature(signature)
        }
        if (isSourceTypeSol(selectedSource)) {
          // If a wallet is selected from dropdown as destination
          signClaim(sessionSolanaKeypair, selectedSource.publicKey)
        } else if (customReceiverPublickey instanceof PublicKey) {
          // if a custom pubkey is set as destination
          signClaim(sessionSolanaKeypair, customReceiverPublickey)
        }
      }
    }, [
      customReceiverPublickey,
      selectedSource,
      sessionSolanaKeypair,
      state.socialId,
    ])

    useEffect(() => {
      if (
        state.claimState === ClaimState.INITIAL &&
        sessionPubkey &&
        requestInFlight === false
      ) {
        setRequestInFlight(true)
        getSolDeposit(sessionPubkey, solUsdcToken, connection)
          .then((payload) => {
            dispatch({
              type: ClaimActionKind.CLAIM_BALANCE_FETCH,
              payload,
            })
            if (payload.expirationTime < Date.now() / 1000) {
              dispatch({
                type: ClaimActionKind.VISUAL_TRANSITION,
                payload: {
                  visualState: ClaimVisualState.EXPIRED,
                },
              })
            }
          })
          .catch((err) => {
            dispatch({
              type: ClaimActionKind.TOO_SLOW,
            })
            setRequestInFlight(false)
          })
      }
    }, [sessionPubkey, requestInFlight, state.claimState, connection])

    const initTx = useCallback(
      async (
        sessionPubkey: PublicKey,
        claimOptions: ClaimParams,
        sessionKeypair: Keypair | null
      ) => {
        try {
          const claimResp = await claimTx(sessionPubkey, claimOptions)
          if (!state.socialId && sessionKeypair) {
            const tx = deserializeTx(claimResp.tx.toString())
            tx.partialSign(sessionKeypair)
            dispatch({
              type: ClaimActionKind.CLAIM_TX_INIT,
              payload: {
                tx,
              },
            })
          } else {
            // the social variant returns the id
            const txId = claimResp.tx_sig
            dispatch({
              type: ClaimActionKind.CLAIM_TX_SEND,
              payload: {
                txId,
              },
            })
          }
        } catch (e) {
          if (e !== "verification not found") {
            dispatch({
              type: ClaimActionKind.TOO_SLOW,
            })
          }
        }
      },
      [state.socialId]
    )

    useEffect(() => {
      if (state.verified === false && sessionPubkey) {
        if (isSourceTypeSol(selectedSource)) {
          startVerification(selectedSource.publicKey.toString())
        } else if (customReceiverPublickey) {
          startVerification(customReceiverPublickey.toString())
        }
      }
    }, [
      startVerification,
      state.verified,
      selectedSource,
      sessionPubkey,
      customReceiverPublickey,
    ])

    useEffect(() => {
      if (verifSignature) {
        dispatch({
          type: ClaimActionKind.CLAIM_TX_VERIFY,
          payload: {
            verified: true,
          },
        })
      }
    }, [verifSignature])

    const handleClaim = useCallback(() => {
      const isBalanceLoaded = state.claimState === ClaimState.BALANCE_LOADED
      const hasSessionKey = sessionPubkey !== null
      const hasCustomReceiver =
        customReceiverPublickey !== null && shouldClaimToCustomReceiverPublicKey
      const isSocialVerification = verifCode > 0 && socialSignature
      const hasSelectedSource = isSourceTypeSol(selectedSource)
      if (
        isBalanceLoaded &&
        hasSessionKey &&
        (hasCustomReceiver || selectedSource)
      ) {
        let params: ClaimParams | null = null

        const isSocialClaim = !!state.socialId && isSocialVerification

        if (isSocialClaim) {
          params = {
            code: verifCode.toString(),
            linkOwnershipVerifier: socialSignature,
          }
        } else if (hasCustomReceiver) {
          params = {
            receiver: customReceiverPublickey,
          }
        } else if (hasSelectedSource) {
          params = {
            receiver: selectedSource.publicKey,
          }
        }

        if (params !== null) {
          initTx(sessionPubkey, params, sessionSolanaKeypair)
        }
      }
    }, [
      state.claimState,
      state.socialId,
      sessionPubkey,
      customReceiverPublickey,
      verifCode,
      socialSignature,
      selectedSource,
      initTx,
      shouldClaimToCustomReceiverPublicKey,
      sessionSolanaKeypair,
    ])

    useEffect(() => {
      if (
        state.claimState === ClaimState.TX_CREATED &&
        state.tx &&
        !state.txId
      ) {
        const dispatchTxSend = (txId: string) => {
          dispatch({
            type: ClaimActionKind.CLAIM_TX_SEND,
            payload: { txId },
          })
        }

        const dispatchTxError = (error: string) => {
          dispatch({
            type: ClaimActionKind.CLAIM_TX_ERROR,
            payload: { error },
          })
        }

        if (customReceiverPublickey) {
          sendTransaction(state.tx).then(dispatchTxSend).catch(dispatchTxError)
        } else if (isSourceTypeSol(selectedSource)) {
          selectedSource.action
            .sendTx(state.tx)
            .then(dispatchTxSend)
            .catch(dispatchTxError)
            .finally(() =>
              selectedSource.action.refetchBalance(selectedSource.publicKey)
            )
        }
      }
    }, [
      customReceiverPublickey,
      selectedSource,
      state.claimState,
      state.tx,
      state.txId,
    ])

    return (
      <ClaimContext.Provider
        value={{
          shouldClaimToCustomReceiverPublicKey,
          setShouldClaimToCustomReceiverPublicKey,
          state,
          dispatch,
          handleClaim,
          socialSignature,
          setSocialSignature,
          customReceiverPublickey,
          setCustomReceiverPublicKey,
        }}
      >
        {children}
      </ClaimContext.Provider>
    )
  }
)

function useClaimContext(): ClaimContextType {
  const context = useContext(ClaimContext)

  if (!context) {
    throw new Error("Missing ClaimContext")
  }

  return context
}

export { ClaimProvider, useClaimContext }
