import { createContext, useContext, useEffect, useState } from "react"
import localeENG from "../../locales/en.json"
import Locale from "../../locales/types"

type LocaleContextType = {
  locale: Locale
}

enum SUPPORTED_LOCALES {
  EN = "en",
}
const LocaleContext = createContext<LocaleContextType | null>(null)

// LocaleProvider => applies locale based on navigator laguage API
const LocaleProvider: React.FC<{ children: React.ReactNode }> =
  function LocaleProvider({ children }) {
    const enLocale: Locale = localeENG as Locale

    const [locale, setLocal] = useState<Locale>(enLocale)

    useEffect(() => {
      const navigatorLanguage = navigator.language.split("-")[0]

      switch (navigatorLanguage) {
        case SUPPORTED_LOCALES.EN:
          setLocal(enLocale)
          return
        default:
          setLocal(enLocale)
      }
    }, [enLocale])

    return (
      <LocaleContext.Provider
        value={{
          locale,
        }}
      >
        {children}
      </LocaleContext.Provider>
    )
  }

function useLocaleContext(): LocaleContextType {
  const context = useContext(LocaleContext)

  if (!context) {
    throw new Error("Missing LocaleContext")
  }

  return context
}

export { LocaleProvider, useLocaleContext }
