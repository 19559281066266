import { Transaction } from "@solana/web3.js"
import { TimeInSeconds } from "../../../lib/types"

export enum ClaimState {
  INITIAL,
  BALANCE_LOADED,
  TX_CREATED,
  TX_SENT,
  TX_ERROR,
  TOO_SLOW,
}

export enum ClaimVisualState {
  LOAD,
  START,
  PROTECTED,
  EXPIRED, // link expired
  SUCCESS,
  CLAIMED, // already claimed by someone else
  WRONG_SIGNATURE,
}

export type ClaimProviderState = {
  claimState: ClaimState
  visualState: ClaimVisualState
  amount: BigInt | null
  displayAmount: string | null
  expirationTime: TimeInSeconds | null
  senderPubkey?: string
  socialId: string | null
  verified: boolean | null
  tx: Transaction | null
  txId: string | null
}

export const claimProviderDefaultState: ClaimProviderState = {
  claimState: ClaimState.INITIAL,
  visualState: ClaimVisualState.LOAD,
  amount: null,
  displayAmount: null,
  expirationTime: null,
  socialId: null,
  verified: null,
  tx: null,
  txId: null,
}

export enum ClaimActionKind {
  CLAIM_BALANCE_FETCH = "CLAIM_BALANCE_FETCH",
  CLAIM_TX_INIT = "CLAIM_TX_INIT",
  CLAIM_TX_VERIFY = "CLAIM_TX_VERIFY",
  CLAIM_TX_SEND = "CLAIM_TX_SEND",
  CLAIM_TX_ERROR = "CLAIM_TX_ERROR",
  RESET = "RESET",
  TOO_SLOW = "TOO_SLOW",
  VISUAL_TRANSITION = "VISUAL_TRANSITION",
}

export interface ClaimBalanceFetch {
  type: ClaimActionKind.CLAIM_BALANCE_FETCH
  payload: {
    amount: BigInt
    displayAmount: string
    expirationTime: TimeInSeconds
    socialId: string | null
    verified: boolean | null
    senderPubkey: string
  }
}

export interface ClaimTxInit {
  type: ClaimActionKind.CLAIM_TX_INIT
  payload: {
    tx: Transaction
  }
}

export interface ClaimTxVerify {
  type: ClaimActionKind.CLAIM_TX_VERIFY
  payload: {
    verified: boolean
  }
}

export interface SendTxSuccessAction {
  type: ClaimActionKind.CLAIM_TX_SEND
  payload: {
    txId: string
  }
}
export interface ClaimTxError {
  type: ClaimActionKind.CLAIM_TX_ERROR
  payload: {
    error: string
  }
}

export interface ResetAction {
  type: ClaimActionKind.RESET
}

export interface TooSlowAction {
  type: ClaimActionKind.TOO_SLOW
}

export interface VisualTransitionAction {
  type: ClaimActionKind.VISUAL_TRANSITION
  payload: {
    visualState: ClaimVisualState
  }
}

export type ClaimActions =
  | ClaimBalanceFetch
  | ClaimTxInit
  | ClaimTxVerify
  | ClaimTxError
  | SendTxSuccessAction
  | TooSlowAction
  | ResetAction
  | VisualTransitionAction
