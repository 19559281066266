import { Box, Button, Grid, Link, Typography, alpha } from "@mui/material"
import { useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { useScrollContext } from "../../../context/v2/scrollContext"
import { useSessionContext } from "../../../context/v2/sessionContext"
import Locale from "../../../locales/types"
import { DesignSystem } from "../../../styles/theme"
import { IconType, SvgIcon } from "../SvgIcon"

const Header: React.FC<{
  locale: Locale
  isMobile: boolean
  designSystem: DesignSystem
}> = ({ locale, isMobile, designSystem }) => {
  const { utility, spacing } = designSystem
  const { currentRoute } = useSessionContext()
  const { isScrolled } = useScrollContext()
  const navigate = useNavigate()
  const settingsPageVisible = currentRoute?.id === "settings"

  const handleToggleSettingsPage = useCallback(() => {
    if (!settingsPageVisible) {
      navigate("/settings")
    } else {
      navigate("/")
    }
  }, [navigate, settingsPageVisible])

  const isSocialAuthRoute = useMemo(
    () => currentRoute?.id === "telegram",
    [currentRoute]
  )

  const headerBackgroundstyle = isScrolled
    ? {
        background: `${alpha(utility.utility1, 0.08)}`,
        backdropFilter: "blur(46px)",
        WebkitBackdropFilter: "blur(46px)",
      }
    : {}

  const renderIcons = (): JSX.Element => {
    return (
      <Box display="flex" justifyContent="flex-start">
        <SvgIcon
          icon={
            isMobile
              ? IconType.GibLogoBlack
              : IconType.GibCashBrandPlusLogoBlack
          }
          style={{
            width: isMobile ? spacing.m : "128px",
            height: spacing.m,
            margin: `${spacing.s} 0`,
          }}
        />
      </Box>
    )
  }
  return (
    <>
      <Box
        style={{
          backgroundColor: "#E0FA75",
          color: "black",
          textAlign: "center",
          position: "fixed",
          width: "100%",
          height: "50px",
          zIndex: 2,
          top: 0,
          padding: `${spacing.xxs} 0`,
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          window.open("https://gib.cash/about", "_blank")
        }}
      >
        <Typography
          variant="title4"
          color="inherit"
          style={{
            fontSize: "20px",
            whiteSpace: "nowrap",
            display: "block",
            animation: "scrollText 90s linear infinite",
          }}
        >
          {/* Repeat the text multiple times */}
          {Array(10).fill(locale.experimental_warning).join(" ")}
        </Typography>
      </Box>

      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        position={"fixed"}
        top={"50px"}
        zIndex={1}
        style={headerBackgroundstyle}
        maxHeight={"72px"}
      >
        <Grid
          container
          paddingLeft={spacing.s}
          paddingRight={spacing.s}
          style={{
            width: "100%",
            padding: `${spacing.xs} ${spacing.s}`,
          }}
        >
          <Grid
            item
            xs={2}
            style={{
              cursor: !isSocialAuthRoute ? "pointer" : "inherit",
            }}
          >
            {!isSocialAuthRoute ? (
              <Link href={window.location.origin}>{renderIcons()}</Link>
            ) : (
              renderIcons()
            )}
          </Grid>
          <Grid
            item
            xs={10}
            container
            direction={"row"}
            alignItems={"center"}
            justifyContent="flex-end"
            spacing={1}
          >
            {!isSocialAuthRoute && (
              <Grid item>
                <Button
                  style={{
                    background: `${utility.utilityOpacity4}`,
                    minWidth: spacing.ml,
                    minHeight: spacing.ml,
                    height: spacing.m,
                    width: spacing.s,
                    padding: spacing.xs,
                    borderRadius: spacing.xs,
                  }}
                  onClick={() => {
                    handleToggleSettingsPage()
                  }}
                >
                  <SvgIcon icon={IconType.Settings} />
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default Header
