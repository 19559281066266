import { Keypair, PublicKey } from "@solana/web3.js"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from "react"
import { solUsdcToken } from "../../../lib/constants"
import { createSocialhash, depositTx } from "../../../lib/requests"
import { SocialAccount, TimeInSeconds } from "../../../lib/types"
import { formatAmount } from "../../../lib/utils"
import { useSessionContext } from "../sessionContext"
import { useSourceContext } from "../sourceContext/sourceContext"
import {
  isConnectedSolExtensionSource,
  isSourceTypeSol,
} from "../sourceContext/types"
import { fundReducer } from "./reducer"
import {
  FundActionKind,
  FundActions,
  FundProviderState,
  FundState,
  fundProviderDefaultState,
} from "./types"

type FundContextType = {
  state: FundProviderState
  dispatch: (action: FundActions) => void
  displayAmount: string
  handleFormSubmit: (
    amount: bigint,
    password: string,
    socialAccount: SocialAccount | null,
    expirationTime: TimeInSeconds
  ) => void
  handleReset: () => void
}

const FundContext = createContext<FundContextType | null>(null)

// FundProvider is concerned with creating and executing Fund instructions during the fund flow
const FundProvider: React.FC<{ children: React.ReactNode }> =
  function FundProvider({ children }) {
    const { handleSetSession, sessionId, sessionSolanaKeypair, onSuccess } =
      useSessionContext()
    const { selectedSource } = useSourceContext()

    const [state, dispatch] = useReducer(fundReducer, fundProviderDefaultState)

    const handleReset = useCallback(() => {
      dispatch({
        type: FundActionKind.FUND_TX_RESET,
      })
    }, [])

    const handleFormSubmit = useCallback(
      (
        fundAmount: BigInt,
        formPassword: string,
        socialAccount: SocialAccount | null,
        expirationTime: TimeInSeconds
      ) => {
        handleSetSession(formPassword, socialAccount || undefined)

        dispatch({
          type: FundActionKind.FUND_FORM_SUBMIT,
          payload: {
            fundAmount,
            formattedAmount: formatAmount(
              Number(fundAmount),
              solUsdcToken.decimals
            ),
            hasPassword: !!formPassword,
            socialAccount,
            expirationTime,
          },
        })

        onSuccess({
          hasPassword: !!formPassword,
          socialAccount,
          expirationTime: expirationTime,
        })
      },
      [handleSetSession, onSuccess]
    )

    const txInit = useCallback(
      async (
        publicKey: PublicKey,
        tokenMint: PublicKey,
        sessionKeypair: Keypair,
        expirationTime: TimeInSeconds,
        fundAmount: BigInt,
        socialHandle: SocialAccount | null
      ) => {
        const socialHash = socialHandle
          ? await createSocialhash(socialHandle)
          : undefined

        depositTx(
          publicKey,
          sessionKeypair.publicKey,
          tokenMint,
          Math.floor(Date.now() / 1000) + expirationTime.valueOf(),
          fundAmount,
          socialHash
        )
          .then((tx) => {
            tx.partialSign(sessionKeypair)
            dispatch({
              type: FundActionKind.FUND_TX_INIT,
              payload: {
                tx,
              },
            })
          })
          .catch((error) => {
            dispatch({
              type: FundActionKind.FUND_TX_ERROR,
              payload: {
                error: error,
              },
            })
          })
      },
      []
    )

    useEffect(() => {
      if (
        sessionSolanaKeypair &&
        isSourceTypeSol(selectedSource) &&
        state.fundState === FundState.FORM_SUBMITTED
      ) {
        txInit(
          selectedSource.publicKey,
          new PublicKey(solUsdcToken.addr),
          sessionSolanaKeypair,
          state.expirationTime,
          state.fundAmount,
          state.socialAccount
        )
      }
    }, [
      txInit,
      sessionSolanaKeypair,
      state.expirationTime,
      state.fundAmount,
      state.fundState,
      state.socialAccount,
      selectedSource,
    ])

    useEffect(() => {
      if (
        isSourceTypeSol(selectedSource) &&
        state.fundState === FundState.TX_CREATED &&
        state.tx
      ) {
        // this assignment is due to the fact that im using multiple async blocks
        // state.tx's availability isn't guaranteed if promises aren't resolved
        // before the next one is called
        let transaction = state.tx
        if (isConnectedSolExtensionSource(selectedSource)) {
          selectedSource.action
            .signTx(transaction)
            .then((tx) => {
              if (tx) {
                dispatch({
                  type: FundActionKind.FUND_TX_SIGN,
                  payload: {
                    tx,
                  },
                })
              }
            })
            .catch((error) => {
              dispatch({
                type: FundActionKind.FUND_TX_ERROR,
                payload: {
                  error: error,
                },
              })
            })
        } else {
          selectedSource.action
            .getSolKeypair()
            .then((keypair) => {
              selectedSource.action.signTx(transaction, keypair).then((tx) => {
                if (tx) {
                  dispatch({
                    type: FundActionKind.FUND_TX_SIGN,
                    payload: {
                      tx,
                    },
                  })
                }
              })
            })
            .catch((error) => {
              dispatch({
                type: FundActionKind.FUND_TX_ERROR,
                payload: {
                  error: error,
                },
              })
            })
        }
      }
    }, [selectedSource, state.fundState, state.tx])

    useEffect(() => {
      if (
        isSourceTypeSol(selectedSource) &&
        state.fundState === FundState.TX_SIGNED &&
        state.tx &&
        !state.txId
      ) {
        selectedSource.action
          .sendTx(state.tx, true)
          .then((txId) => {
            // power user #1 wants to change the route (without navigating to the claim page)
            window.history.pushState(null, "", `#${sessionId}`)
            dispatch({
              type: FundActionKind.FUND_TX_SEND,
              payload: {
                txId,
              },
            })
          })
          .catch((error) => {
            dispatch({
              type: FundActionKind.FUND_TX_ERROR,
              payload: {
                error: error,
              },
            })
          })
      }
    }, [sessionId, state.fundState, state.tx, state.txId, selectedSource])

    return (
      <FundContext.Provider
        value={{
          state,
          dispatch,
          handleFormSubmit,
          displayAmount: formatAmount(
            Number(state.fundAmount),
            solUsdcToken.decimals
          ),
          handleReset,
        }}
      >
        {children}
      </FundContext.Provider>
    )
  }

function useFundContext(): FundContextType {
  const context = useContext(FundContext)

  if (!context) {
    throw new Error("Missing FundContext")
  }

  return context
}

export { FundProvider, useFundContext }
