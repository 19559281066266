import { useTheme } from "@mui/material"
import Arbitrum from "../../assets/images/Arbitrum.svg"
import ArrowTopRight from "../../assets/images/ArrowTopRight.svg"
import Check from "../../assets/images/Check.svg"
import CheckBox from "../../assets/images/CheckBox.svg"
import ChevronDown from "../../assets/images/ChevronDown.svg"
import ChevronDownBlack from "../../assets/images/ChevronDownBlack.svg"
import ChevronLeft from "../../assets/images/ChevronLeft.svg"
import ChevronUp from "../../assets/images/ChevronUp.svg"
import Clock from "../../assets/images/Clock.svg"
import CloseX from "../../assets/images/CloseX.svg"
import ClosedEye from "../../assets/images/ClosedEye.svg"
import CopyBlack from "../../assets/images/CopyBlack.svg"
import Dollar from "../../assets/images/Dollar.svg"
import Download from "../../assets/images/Download.svg"
import Ellipse from "../../assets/images/Ellipse.svg"
import EuroCoin from "../../assets/images/EuroCoin.svg"
import GibCashBrandPlusLogoBlack from "../../assets/images/GibCashBrandPlusLogoBlack.svg"
import GibCashGreen from "../../assets/images/GibCashGreen.svg"
import GibCashSide from "../../assets/images/GibCashSide.svg"
import GibLogoBlack from "../../assets/images/GibLogoBlack.svg"
import InfoIcon from "../../assets/images/InfoIcon.svg"
import Link from "../../assets/images/Link.svg"
import Lock from "../../assets/images/Lock.svg"
import OpenEye from "../../assets/images/OpenEye.svg"
import OptionsBlack from "../../assets/images/Options.svg"
import PasskeyStep1 from "../../assets/images/PasskeyStep1.svg"
import PasskeyStep2 from "../../assets/images/PasskeyStep2.svg"

import Bulb from "../../assets/images/Bulb.svg"
import PhantomLogoPurple from "../../assets/images/PhantomLogoPurple.svg"
import QrCode from "../../assets/images/QrCode.svg"
import Settings from "../../assets/images/Settings.svg"
import ShareWhite from "../../assets/images/ShareWhite.svg"
import Solana from "../../assets/images/Solana.svg"
import TelegramLogo from "../../assets/images/TelegramLogo.svg"
import TwitterLogo from "../../assets/images/TwitterLogo.svg"
import UltimateLogo from "../../assets/images/UltimateLogo.svg"
import UsdcToken from "../../assets/images/UsdcTokenIcon.svg"
import VecPointer from "../../assets/images/VecPointer.svg"
import WalletBlack from "../../assets/images/WalletBlack.svg"
import WalletWhite from "../../assets/images/WalletWhite.svg"
import CrossCircle from "../../assets/images/crossCircle.svg"
import Pen from "../../assets/images/pen.svg"
import CrossTick from "../../assets/images/tickCircle.svg"

// TODO:
// SVG's can be made Black/White using CSS filters
// https://developer.mozilla.org/en-US/docs/Web/CSS/filter
// would chop svg imports in half
export enum IconType {
  Dollar,
  Download,
  CopyBlack,
  ArrowTopRight,
  Ellipse,
  VecPointer,
  GibCashGreen,
  Clock,
  CrossCircle,
  CrossTick,
  Link,
  Check,
  CheckBox,
  WalletWhite,
  WalletBlack,
  EuroCoin,
  GibLogoBlack,
  GibCashBrandPlusLogoBlack,
  ChevronDownBlack,
  ChevronUp,
  InfoIcon,
  ChevronDown,
  ChevronLeft,
  ShareWhite,
  OptionsBlack,
  Lock,
  ClosedEye,
  OpenEye,
  GibCashSide,
  UltimateLogo,
  QrCode,
  CloseX,
  TelegramLogo,
  TwitterLogo,
  Solana,
  Arbitrum,
  Settings,
  // obviously not sustainable if we add 1000's of coins. future problem.
  UsdcToken,
  PhantomLogoPurple,
  Pen,
  PasskeyStep1,
  PasskeyStep2,
  Bulb,
}

interface Props {
  icon: IconType
  style?: React.CSSProperties | undefined
}

export const SvgIcon = ({ icon, style }: Props): JSX.Element => {
  const {
    palette: {
      designSystem: { spacing },
    },
  } = useTheme()
  let iconPath: null | string = null
  let alt: string = ""

  switch (icon) {
    case IconType.CrossCircle:
      iconPath = CrossCircle
      alt = "CrossCircle"
      break
    case IconType.CrossTick:
      iconPath = CrossTick
      alt = "CrossTick"
      break
    case IconType.Ellipse:
      iconPath = Ellipse
      alt = "Ellipse"
      break
    case IconType.ArrowTopRight:
      iconPath = ArrowTopRight
      alt = "ArrowTopRight"
      break
    case IconType.VecPointer:
      iconPath = VecPointer
      alt = "VecPointer"
      break
    case IconType.Check:
      iconPath = Check
      alt = "Check"
      break
    case IconType.GibCashGreen:
      iconPath = GibCashGreen
      alt = "GibCashGreen"
      break
    case IconType.ChevronLeft:
      iconPath = ChevronLeft
      alt = "ChevronLeft"
      break
    case IconType.Dollar:
      iconPath = Dollar
      alt = "Dollar"
      break
    case IconType.Download:
      iconPath = Download
      alt = "Download"
      break
    case IconType.CheckBox:
      iconPath = CheckBox
      alt = "CheckBox"
      break
    case IconType.Link:
      iconPath = Link
      alt = "Link"
      break
    case IconType.Clock:
      iconPath = Clock
      alt = "Clock"
      break
    case IconType.InfoIcon:
      iconPath = InfoIcon
      alt = "InfoIcon"
      break
    case IconType.TwitterLogo:
      iconPath = TwitterLogo
      alt = "TwitterLogo"
      break
    case IconType.ChevronDown:
      iconPath = ChevronDown
      alt = "ChevronDown"
      break
    case IconType.ChevronUp:
      iconPath = ChevronUp
      alt = "ChevronUp"
      break
    case IconType.Arbitrum:
      iconPath = Arbitrum
      alt = "Arbitrum"
      break
    case IconType.Solana:
      iconPath = Solana
      alt = "Solana"
      break
    case IconType.TelegramLogo:
      iconPath = TelegramLogo
      alt = "TelegramLogo"
      break
    case IconType.CloseX:
      iconPath = CloseX
      alt = "CloseX"
      break
    case IconType.QrCode:
      iconPath = QrCode
      alt = "QrCode"
      break
    case IconType.WalletWhite:
      iconPath = WalletWhite
      alt = "WalletWhite"
      break
    case IconType.GibCashSide:
      iconPath = GibCashSide
      alt = "GibCashSide"
      break
    case IconType.WalletBlack:
      iconPath = WalletBlack
      alt = "WalletBlack"
      break
    case IconType.GibLogoBlack:
      iconPath = GibLogoBlack
      alt = "GibLogoBlack"
      break
    case IconType.ShareWhite:
      iconPath = ShareWhite
      alt = "ShareWhite"
      break
    case IconType.CopyBlack:
      iconPath = CopyBlack
      alt = "CopyBlack"
      break
    case IconType.OptionsBlack:
      iconPath = OptionsBlack
      alt = "OptionsBlack"
      break
    case IconType.GibCashBrandPlusLogoBlack:
      iconPath = GibCashBrandPlusLogoBlack
      alt = "GibCashBrandPlusLogoBlack"
      break
    case IconType.Lock:
      iconPath = Lock
      alt = "Lock"
      break
    case IconType.OpenEye:
      iconPath = OpenEye
      alt = "OpenEye"
      break
    case IconType.ClosedEye:
      iconPath = ClosedEye
      alt = "ClosedEye"
      break
    // obviously not sustainable
    case IconType.UsdcToken:
      iconPath = UsdcToken
      alt = "UsdcToken"
      break
    case IconType.ChevronDownBlack:
      iconPath = ChevronDownBlack
      alt = "ChevronDownBlack"
      break
    case IconType.EuroCoin:
      iconPath = EuroCoin
      alt = "EuroCoin"
      break
    case IconType.UltimateLogo:
      iconPath = UltimateLogo
      alt = "UltimateLogo"
      break
    case IconType.PhantomLogoPurple:
      iconPath = PhantomLogoPurple
      alt = "PhantomLogo"
      break
    case IconType.Settings:
      iconPath = Settings
      alt = "Settings"
      break
    case IconType.Pen:
      iconPath = Pen
      alt = "Pen"
      break
    case IconType.PasskeyStep1:
      iconPath = PasskeyStep1
      alt = "PasskeyStep1"
      break
    case IconType.PasskeyStep2:
      iconPath = PasskeyStep2
      alt = "PasskeyStep2"
      break
    case IconType.Bulb:
      iconPath = Bulb
      alt = "lightbulb"
  }

  return (
    <img
      src={iconPath}
      alt={alt}
      style={{
        minWidth: style?.width ? style.width : spacing.sm,
        minHeight: style?.height ? style.height : spacing.sm,
        margin: spacing.xs,
        ...style,
      }}
    />
  )
}
