import "@mui/material/styles/createPalette"

import { createTheme } from "@mui/material"
import { typography } from "./fonts"

/**
 * Represents a particular unit spacing. Use with paddings/margins/heights/widths/border-radiuses.
 * @interface
 */
export interface Spacing {
  xxs: string
  xs: string
  xs_5: string
  s: string
  s_5: string
  sm: string
  sm_5: string
  m: string
  m_5: string
  ml: string
  ml_5: string
  l: string
  l_5: string
  xl: string
  xl_5: string
  xxl: string
}

export interface Utility {
  utility1: string
  utilityOpacity50: string
  utilityOpacity30: string
  utilityOpacity8: string
  utilityOpacity4: string
  utilityInverted: string
  utilityInvertedOpacity4: string
  utilityInvertedOpacity60: string
}

export interface Base {
  light1: string
  light2: string
}

export interface DesignSystem {
  primary: {
    yellow: string
    primary2: string
    olive: string
  }
  secondary: {
    darkBlue: string
    blue: string
    grey: string
    darkRed: string
    red: string
    pink: string
  }
  utility: Utility
  spacing: Spacing
  base: Base
}
declare module "@mui/material/styles" {
  export interface PaletteOptions {
    designSystem: DesignSystem
  }
  export interface Palette {
    designSystem: DesignSystem
  }
}

const designSystem: DesignSystem = {
  primary: {
    yellow: "#D7FF5F",
    primary2: "#D7E1C8",
    olive: "#879B64",
  },
  secondary: {
    darkBlue: "#59AA9E",
    blue: "#74F1D3",
    grey: "#D1EAE3",
    darkRed: "#9B5537",
    red: "#FF4603",
    pink: "#FFC0A4",
  },
  utility: {
    utility1: "#232323",
    utilityOpacity50: "#23232380",
    utilityOpacity30: "#2323234D",
    utilityOpacity8: "#23232314",
    utilityOpacity4: "#2323230A",
    utilityInverted: "#FFF",
    utilityInvertedOpacity4: "#FFFFFF0A",
    utilityInvertedOpacity60: "#FFFFFF99",
  },
  base: {
    light1: "#FFFFFF",
    light2: "#FFFFFFCC",
  },
  spacing: {
    xxs: "4px",
    xs: "8px",
    xs_5: "12px",
    s: "16px",
    s_5: "20px",
    sm: "24px",
    sm_5: "28px",
    m: "32px",
    m_5: "36px",
    ml: "40px",
    ml_5: "44px",
    l: "48px",
    l_5: "52px",
    xl: "56px",
    xl_5: "60px",
    xxl: "64px",
  },
}

export const theme = createTheme({
  typography: typography,
  palette: {
    designSystem,
  },
})
