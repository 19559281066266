import { Keypair, PublicKey } from "@solana/web3.js"
import localeENG from "../locales/en.json"
import Locale from "../locales/types"
import { generatePasskeyName } from "./passkeyNameGenerator"
import { Sleep } from "./utils"

export enum PassKeyError {
  NoWebAuth,
  RegisterError,
  AuthError,
  NoAccountError,
  NoAssertion,
  NoResponse,
  InvalidResponseType,
  NoUserHandle,
  PubkeyValidationFailed,
}

export function isPassKeyError(value: any): value is PassKeyError {
  return Object.values(PassKeyError).includes(value)
}

export function explainError(error: PassKeyError, locale: Locale): string {
  switch (error) {
    case PassKeyError.NoWebAuth:
      return locale.passkey_error_nowebauth
    case PassKeyError.RegisterError:
      return locale.passkey_error_register
    case PassKeyError.AuthError:
      return locale.passkey_error_auth
    case PassKeyError.NoAssertion:
      return locale.passkey_error_noassertion
    case PassKeyError.NoResponse:
      return locale.passkey_error_noresponse
    case PassKeyError.InvalidResponseType:
      return locale.passkey_error_invalidresponse
    case PassKeyError.NoUserHandle:
      return locale.passkey_error_nouserhandle
    case PassKeyError.NoAccountError:
      return locale.passkey_error_auth
    case PassKeyError.PubkeyValidationFailed:
      return locale.passkey_error_keyvalidationfailed
  }
}

export async function passKeyFlow(): Promise<PayloadSchema> {
  return authenticate()
    .then((wallet) => {
      const payloadSchema = {
        solKey: wallet.sol.publicKey.toBase58(),
      } as PayloadSchema
      return Promise.resolve(payloadSchema)
    })
    .catch(async (err: PassKeyError) => {
      if (err === PassKeyError.NoAccountError) {
        await Sleep(300)
        return registerPasskey()
          .then((wallet) => Promise.resolve(wallet))
          .catch((err: PassKeyError) =>
            Promise.reject(explainError(err, localeENG as Locale))
          )
      } else {
        return Promise.reject(err)
      }
    })
}

export interface GibWallet {
  sol: Keypair
}

export interface PayloadSchema {
  solKey: string
}

namespace PasskeyStorage {
  const storageKey = "passkey-storage"

  export function saveInformation(wallet: PayloadSchema) {
    localStorage.setItem(storageKey, JSON.stringify(wallet))
  }

  export function loadInformation(): PayloadSchema | null {
    const item = localStorage.getItem(storageKey)
    if (item === null) {
      return null
    }
    const parsed = JSON.parse(item) as PayloadSchema
    if (parsed.solKey === undefined) {
      return null
    }
    return parsed
  }

  export function deleteInformation() {
    localStorage.removeItem(storageKey)
  }
}

export async function registerPasskey(): Promise<PayloadSchema> {
  if (!window.PublicKeyCredential) {
    return Promise.reject(PassKeyError.NoWebAuth)
  }

  const challenge = window.crypto.getRandomValues(new Uint8Array(32))
  const seed = await makeSeed()

  const publicKey = {
    challenge: challenge,
    rp: {
      name: "Gib Cash",
    },
    user: {
      id: seed,
      name: generatePasskeyName(),
      displayName: "Authenticate Gib.Cash",
    },
    pubKeyCredParams: [
      {
        type: "public-key",
        alg: -7,
      },
      {
        type: "public-key",
        alg: -35,
      },
      {
        type: "public-key",
        alg: -36,
      },
      {
        type: "public-key",
        alg: -257,
      },
      {
        type: "public-key",
        alg: -258,
      },
      {
        type: "public-key",
        alg: -259,
      },
      {
        type: "public-key",
        alg: -37,
      },
      {
        type: "public-key",
        alg: -38,
      },
      {
        type: "public-key",
        alg: -39,
      },
      {
        type: "public-key",
        alg: -8,
      },
    ],
    authenticatorSelection: {
      residentKey: "preferred",
    },
    attestation: "none",
    timeout: 300000,
  } as PublicKeyCredentialCreationOptions

  return new Promise((resolve, reject) => {
    navigator.credentials
      .create({ publicKey })
      .then(function (newCredentialInfo) {
        const wallet = {
          sol: keySolana(seed),
        }
        const payloadSchema = {
          solKey: wallet.sol.publicKey.toBase58(),
        } as PayloadSchema
        // PasskeyStorage.saveInformation(payloadSchema)
        resolve(payloadSchema)
      })
      .catch(function (err) {
        console.log(err)
        reject(PassKeyError.RegisterError)
      })
  })
}

async function makeSeed(): Promise<Uint8Array> {
  const array = new Uint8Array(64)
  return window.crypto.getRandomValues(array)
}

function keySolana(seed: Uint8Array): Keypair {
  const kp = Keypair.fromSeed(seed.slice(0, 32))
  return kp
}

function getSeed(): Promise<Uint8Array> {
  if (!window.PublicKeyCredential) {
    Promise.reject(PassKeyError.NoWebAuth)
  }

  const challenge = window.crypto.getRandomValues(new Uint8Array(32))
  const options = {
    challenge: challenge,
    timeout: 300000,
  }

  return navigator.credentials
    .get({ publicKey: options })
    .then(function (assertion) {
      if (assertion === null) {
        return Promise.reject(PassKeyError.NoAssertion)
      }
      /*if (!(assertion instanceof PublicKeyCredential)) {
        return Promise.reject(PassKeyError.NoAssertion)
      }*/
      const credential = assertion as unknown as PublicKeyCredential
      if (credential.response === undefined) {
        return Promise.reject(PassKeyError.NoResponse)
      }
      const response = credential.response as AuthenticatorAssertionResponse
      /*if (!(response instanceof AuthenticatorAssertionResponse)) {
        return Promise.reject(PassKeyError.InvalidResponseType)
      }*/
      if (response.userHandle === undefined) {
        return Promise.reject(PassKeyError.NoUserHandle)
      }
      if (response.userHandle === null) {
        return Promise.reject(PassKeyError.NoUserHandle)
      }
      const seed = new Uint8Array(response.userHandle)

      return Promise.resolve(seed)
    })
    .catch(function (err) {
      console.log(err)
      const ex = err as DOMException
      if (!ex.name) {
        return Promise.reject(PassKeyError.AuthError)
      }
      if (ex.name === "NotAllowedError") {
        return Promise.reject(PassKeyError.NoAccountError)
      }
      return Promise.reject(PassKeyError.AuthError)
    })
}

export async function getSolKeypair(): Promise<Keypair> {
  let seed = await getSeed()
  return Promise.resolve(keySolana(seed))
}

export async function authenticate(
  pubkeyMatches?: PublicKey
): Promise<GibWallet> {
  let seed = await getSeed()

  const sol = keySolana(seed)
  const wallet = {
    sol: sol,
  } as GibWallet

  const payloadSchema = {
    solKey: wallet.sol.publicKey.toBase58(),
  } as PayloadSchema

  if (!pubkeyMatches) {
    PasskeyStorage.saveInformation(payloadSchema)
    return Promise.resolve(wallet)
  } else {
    if (pubkeyMatches.toString() === sol.publicKey.toString()) {
      PasskeyStorage.saveInformation(payloadSchema)
      return Promise.resolve(wallet)
    } else {
      return Promise.reject(PassKeyError.PubkeyValidationFailed)
    }
  }
}

export function login(): PayloadSchema | null {
  return PasskeyStorage.loadInformation()
}

export function isLoggedIn(): PayloadSchema | null {
  return PasskeyStorage.loadInformation()
}

export function logout() {
  return PasskeyStorage.deleteInformation()
}
