import { createContext, useContext, useEffect, useState } from "react"
import { getVerification, postVerification } from "../../lib/requests"
import { useSessionContext } from "./sessionContext"

type SocialAuthContextType = {
  startVerification: (address: string) => void
  verifSignature: string
  verifCode: number
  setVerifCode: (code: number) => void
}

const SocialAuthContext = createContext<SocialAuthContextType | null>(null)

const SocialAuthProvider: React.FC<{ children: React.ReactNode }> =
  function SocialAuthProvider({ children }) {
    const [verifCode, setVerifCode] = useState<number>(0)
    const [verifSignature, setVerifSignature] = useState<string>("")

    const [userAddress, setUserAddress] = useState<string | null>(null)
    const { sessionPubkey } = useSessionContext()

    const startVerification = (address: string) => {
      setUserAddress(address)
    }

    useEffect(() => {
      const fetchVerifCode = async () => {
        if (userAddress && sessionPubkey) {
          const verificationCode = await postVerification(
            sessionPubkey.toString(),
            userAddress
          )
          setVerifCode(verificationCode)
        }
      }

      fetchVerifCode()
    }, [userAddress, sessionPubkey])

    useEffect(() => {
      let keepTrying = true

      const tryRetrieveSocialSig = async () => {
        if (verifCode > 0 && keepTrying) {
          try {
            const sig = await getVerification(verifCode)
            if (!sig.startsWith("no verification found for")) {
              setVerifSignature(sig)
              keepTrying = false
            } else {
              setTimeout(() => {
                tryRetrieveSocialSig()
              }, 2000)
            }
          } catch {
            setTimeout(() => {
              tryRetrieveSocialSig()
            }, 2000)
          }
        }
      }

      tryRetrieveSocialSig()

      return () => {
        keepTrying = false
      } // Cleanup to prevent running when component unmounts
    }, [verifCode])

    return (
      <SocialAuthContext.Provider
        value={{
          startVerification,
          verifSignature,
          verifCode,
          setVerifCode,
        }}
      >
        {children}
      </SocialAuthContext.Provider>
    )
  }

function useSocialAuthContext(): SocialAuthContextType {
  const context = useContext(SocialAuthContext)

  if (!context) {
    throw new Error("Missing SocialAuthContext")
  }

  return context
}

export { SocialAuthProvider, useSocialAuthContext }
