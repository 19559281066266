import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react"
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui"

// WalletProvider is concerned with connecting wallet extensions
const SolanaWalletProvider: React.FC<{ children: React.ReactNode }> =
  function SolanaWalletProvider({ children }) {
    const endpoint = process.env.REACT_APP_QUICKNODE_RPC_URL
      ? process.env.REACT_APP_QUICKNODE_RPC_URL
      : ""

    return (
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={[]} autoConnect>
          <WalletModalProvider>{children}</WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    )
  }

export default SolanaWalletProvider
