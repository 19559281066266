import { Grid, useMediaQuery, useTheme } from "@mui/material"
import { Toaster } from "react-hot-toast"
import { useLocaleContext } from "../context/v2/localeContext"
import { ScrollProvider } from "../context/v2/scrollContext"
import VideoBackground from "./VideoBackground"
import RoutesWrapper from "./body/RoutesWrapper"
import Footer from "./shared/Footer.tsx/Footer"
import Header from "./shared/Header/Header"
import { IconType, SvgIcon } from "./shared/SvgIcon"

export default function AppBody() {
  const {
    breakpoints,
    palette: { designSystem },
  } = useTheme()

  const { locale } = useLocaleContext()

  const isMobile = useMediaQuery(breakpoints.down("md"))

  return (
    <ScrollProvider>
      <Grid
        container
        wrap="nowrap"
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        className="App"
        style={{
          display: "flex",
          minHeight: "100vh",
          height: "100%",
          padding: "0",
          margin: "0",
          flexDirection: "column",
        }}
      >
        <VideoBackground />
        <Toaster
          toastOptions={{
            success: {
              icon: <SvgIcon icon={IconType.CrossCircle} />,
            },
            error: {
              icon: <SvgIcon icon={IconType.CrossCircle} />,
            },
            style: {
              padding: "10px 30px",
            },
          }}
        />
        <Header
          locale={locale}
          isMobile={isMobile}
          designSystem={designSystem}
        />
        <RoutesWrapper />
        <Footer designSystem={designSystem} />
      </Grid>
    </ScrollProvider>
  )
}
