import { Transaction } from "@solana/web3.js"
import { SocialAccount, TimeInSeconds } from "../../../lib/types"

export enum FundState {
  INITIAL,
  FORM_SUBMITTED,
  TX_CREATED,
  TX_SIGNED,
  TX_SENT,
  TX_ERROR,
}

export enum VisualState {
  FORM,
  PROCESSING,
  SUCCESS,
}

export type FundProviderState = {
  fundState: FundState
  visualState: VisualState
  fundAmount: BigInt
  formattedAmount: string
  socialAccount: SocialAccount | null
  expirationTime: TimeInSeconds
  tx: Transaction | null
  txId: string | null
  error: string | null
}

export const fundProviderDefaultState: FundProviderState = {
  fundState: FundState.INITIAL,
  visualState: VisualState.FORM,
  fundAmount: BigInt(0),
  formattedAmount: "0",
  socialAccount: null,
  tx: null,
  txId: null,
  expirationTime: TimeInSeconds.THREE_DAYS,
  error: null,
}

export enum FundActionKind {
  FUND_FORM_SUBMIT = "FUND_FORM_SUBMIT",
  FUND_TX_INIT = "FUND_TX_INIT",
  FUND_TX_SIGN = "FUND_TX_SIGN",
  FUND_TX_SEND = "FUND_TX_SEND",
  FUND_TX_ERROR = "FUND_TX_ERROR",
  FUND_TX_RESET = "FUND_TX_RESET",
  VISUAL_TRANSITION = "VISUAL_TRANSITION",
}

export interface fundTxFormSubmit {
  type: FundActionKind.FUND_FORM_SUBMIT
  payload: {
    fundAmount: BigInt
    formattedAmount: string
    hasPassword: boolean
    socialAccount: SocialAccount | null
    expirationTime: TimeInSeconds
  }
}

export interface fundTxInit {
  type: FundActionKind.FUND_TX_INIT
  payload: {
    tx: Transaction
  }
}

export interface fundTxSign {
  type: FundActionKind.FUND_TX_SIGN
  payload: {
    tx: Transaction
  }
}

export interface fundTxSend {
  type: FundActionKind.FUND_TX_SEND
  payload: {
    txId: string
  }
}

export interface fundTxError {
  type: FundActionKind.FUND_TX_ERROR
  payload: {
    error: string
  }
}

export interface fundTxReset {
  type: FundActionKind.FUND_TX_RESET
}

export interface fundTxVisualState {
  type: FundActionKind.VISUAL_TRANSITION
  payload: {
    visualState: VisualState.SUCCESS
  }
}

export type FundActions =
  | fundTxFormSubmit
  | fundTxInit
  | fundTxSign
  | fundTxSend
  | fundTxError
  | fundTxReset
  | fundTxVisualState
