import { TypographyOptions } from "@mui/material/styles/createTypography"
import React from "react"

const utility1 = "#232323"
// the word "branding" is used to indicate that a font is used and associated with the brand rather than with functionality of app

const fontDefinitions: TypographyOptions = {
  veryLarge: {
    // branding header
    fontWeight: 500,
    fontSize: "80px",
    lineHeight: "90px",
    letterSpacing: "2.4px",
    textAlign: "center",
    color: utility1,
    fontFamily: ["VC Nudge Bold", "Roboto"].join(","),
  },
  large: {
    // branding header, but smaller
    fontWeight: 500,
    fontSize: "54px",
    lineHeight: "80px",
    letterSpacing: "0.01em",
    textAlign: "center",
    color: utility1,
    fontFamily: ["VC Nudge Bold", "Roboto"].join(","),
  },
  title1: {
    // branding rarely used (imprint stuff)
    fontWeight: 500,
    fontSize: "40px",
    lineHeight: "48px",
    letterSpacing: "0.01em",
    textAlign: "center",
    color: utility1,
    fontFamily: ["VC Nudge Bold", "Roboto"].join(","),
  },
  title2: {
    // branding section header
    fontWeight: 500,
    fontSize: "32px",
    lineHeight: "44.8px",
    letterSpacing: "0.02em",
    textAlign: "center",
    color: utility1,
    fontFamily: ["VC Nudge Bold", "Roboto"].join(","),
  },
  title3: {
    // used in headers of forms ("review")
    fontWeight: 700,
    fontSize: "16px",
    letterSpacing: "-0.5px",
    color: utility1,
    fontFamily: "Inter",
    fontStyle: "normal",
    lineHeight: "22px",
  },
  title4: {
    // rarely used (imprint stuff)
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "22.4px",
    letterSpacing: "0em",
    textAlign: "center",
    color: utility1,
    fontFamily: ["VC Nudge Bold", "Roboto"].join(","),
  },
  label1: {
    // rarely used
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0em",
    textAlign: "center",
    color: utility1,
    fontFamily: ["VC Nudge Normal Medium", "Roboto"].join(","),
  },
  label2: {
    // used in text within forms (like body3)
    fontSize: "16px",
    letterSpacing: "-0.5px",
    color: utility1,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
  },
  label3: {
    // used in text within forms
    fontSize: "13px",
    letterSpacing: "-0.065px",
    color: utility1,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  },
  body1: {
    // used for branding style
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "28px",
    letterSpacing: "0em",
    textAlign: "center",
    color: utility1,
    fontFamily: ["VC Nudge Normal Regular", "Roboto"].join(","),
  },
  body2: {
    // used for branding style
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "22.4px",
    letterSpacing: "0em",
    textAlign: "center",
    color: utility1,
    fontFamily: ["VC Nudge Normal Regular", "Roboto"].join(","),
  },
  body3: {
    // used in text within forms (like label2)
    fontSize: "16px",
    letterSpacing: "-0.5px",
    color: utility1,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "18px",
  },
  body4: {
    // used in small explanatory text
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "8px",
    letterSpacing: "0em",
    textAlign: "center",
    color: utility1,
    fontFamily: "Inter",
  },
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    veryLarge: React.CSSProperties
    large: React.CSSProperties
    title1: React.CSSProperties
    title2: React.CSSProperties
    title3: React.CSSProperties
    title4: React.CSSProperties
    label1: React.CSSProperties
    label2: React.CSSProperties
    label3: React.CSSProperties
    body3: React.CSSProperties
    body4: React.CSSProperties
  }

  interface TypographyVariantsOptions {
    veryLarge?: React.CSSProperties
    large?: React.CSSProperties
    title1?: React.CSSProperties
    title2?: React.CSSProperties
    title3?: React.CSSProperties
    title4?: React.CSSProperties
    label1?: React.CSSProperties
    label2?: React.CSSProperties
    label3?: React.CSSProperties
    body1?: React.CSSProperties
    body2?: React.CSSProperties
    body3?: React.CSSProperties
    body4?: React.CSSProperties
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    veryLarge: true
    large: true
    title1: true
    title2: true
    title3: true
    title4: true
    label1: true
    label2: true
    label3: true
    body1: true
    body2: true
    body3: true
    body4: true
  }
}

export const typography: TypographyOptions = {
  fontFamily: [
    "VC Nudge Normal Regular",
    "VC Nudge Normal Medium",
    "VC Nudge Bold",
    "Roboto",
  ].join(","),
  fontSize: 16,
  ...fontDefinitions,
}
