import "reflect-metadata"

import { SocialPlatform } from "./types"
import { DappConfiguration, SelectedChain, base64UrlSafeCharSet } from "./utils"

const bitSizeMetadataKey = Symbol("reservedBits")

/**
 * Decorator function to set bitsize metadata for a boolean property
 * @param size - The size in bits.
 * @param isBool - Whether the property is boolean. (Optional, defaults to false)
 * @returns A decorator function.
 */
function boolean() {
  return Reflect.metadata(bitSizeMetadataKey, {
    size: 1,
    isBool: true,
  })
}

/**
 * Decorator function to set bitsize metadata for an enum property
 * @param size - The size in bits.
 * @param isBool - Whether the property is boolean. (Optional, defaults to false)
 * @returns A decorator function.
 */
function bitSize(size: number) {
  return Reflect.metadata(bitSizeMetadataKey, {
    size,
    isBool: false,
  })
}

/**
 * Retrieves bitsize metadata for a property.
 * @param target - The target object.
 * @param propertyKey - The property key.
 * @returns An object containing size and isBool properties, or undefined if metadata is not found.
 */
function getBitSize(
  target: any,
  propertyKey: string
): { size: number; isBool: boolean } | undefined {
  return Reflect.getMetadata(bitSizeMetadataKey, target, propertyKey)
}

/**
 * Type definition for a bit (either 0 or 1).
 */
type Bit = 0 | 1

/**
 * Converts a base64 string to an array of bits.
 * @param input - The input base64 string.
 * @returns An array of bits (0s and 1s).
 */
const base64StrToBitsArray = (input: string): Bit[] => {
  let bitsArray: Bit[] = []
  for (let i = 0; i < input.length; i++) {
    let num = base64UrlSafeCharSet.indexOf(input.charAt(i))

    for (let j = 0; j < 6; j++) {
      bitsArray.push(((num >> j) & 1) as Bit)
    }
  }
  return bitsArray
}

class GibConfiguration implements DappConfiguration {
  @boolean()
  isPasswordProtected: boolean

  @bitSize(2)
  chain: SelectedChain

  @bitSize(2)
  social: SocialPlatform

  @boolean()
  updateSchema: boolean

  private constructor() {
    // initialize with default
    this.isPasswordProtected = false
    this.chain = SelectedChain.SOLANA
    this.social = SocialPlatform.TELEGRAM
    this.updateSchema = false
  }

  public bitsTotal(): number {
    const props = Object.keys(this)
    const requiredBits = props.reduce((acc, prop) => {
      const reservedBits = getBitSize(GibConfiguration.prototype, prop)

      return acc + (reservedBits?.size || 0)
    }, 0)

    return requiredBits
  }

  static fromConfig(config: DappConfiguration) {
    const instance = new GibConfiguration()

    instance.isPasswordProtected = config.isPasswordProtected
    instance.chain = config.chain
    instance.social = config.social
    instance.updateSchema = config.updateSchema

    return instance
  }

  static default(): GibConfiguration {
    return new GibConfiguration()
  }

  static deserialize(config: string): GibConfiguration {
    // base64 to bits
    const bits = base64StrToBitsArray(config)
    // default object
    const gibInstance = new GibConfiguration()
    if (gibInstance.bitsTotal() > bits.length) {
      throw Error("instance requires more bits than contained in input")
    }

    const props = Object.keys(gibInstance)

    let cursor = 0
    for (let i = 0; i < props.length; i++) {
      const prop = props[i] as keyof GibConfiguration

      const reservedBits = getBitSize(GibConfiguration.prototype, prop)

      if (reservedBits === undefined) {
        console.warn(`property '${prop}' has no bitsize. ignoring...`)
        continue
      }

      const { size, isBool } = reservedBits

      const localCursor = cursor
      const value = [...Array(reservedBits).keys()].reduce((acc, num) => {
        const bit = bits[localCursor + num]
        return acc | (bit << num)
      }, 0)

      cursor += size

      if (isBool) {
        // @ts-ignore
        gibInstance[prop] = Boolean(value)
      } else {
        // @ts-ignore
        gibInstance[prop] = value
      }
    }

    return gibInstance
  }

  serialize(): string {
    const props = Object.keys(this)
    let bits: Bit[] = []

    for (let i = 0; i < props.length; i++) {
      const prop = props[i]
      const bitsize = getBitSize(GibConfiguration.prototype, prop)

      if (bitsize === undefined) {
        console.warn(`property '${prop}' has no bitsize. ignoring...`)
        continue
      }

      const { size } = bitsize

      // @ts-ignore
      const value: number = this[prop]

      const propertyBits = [...Array(size).keys()].reduce(
        (acc, num) => [...acc, ((value >> num) & 1) as Bit],
        [] as Bit[]
      )

      bits.push(...propertyBits)
    }

    let cursor = 0
    let value = 0
    let result: string | null = null

    for (let i = 0; i < bits.length; i++) {
      // base64 encoding uses 6 bits, so after every 6 bits, a new character
      // is written to the result
      if (i % 6 === 0) {
        if (result !== null) {
          result += base64UrlSafeCharSet[value]
        } else {
          result = ""
        }

        value = 0
        cursor = 0
      }

      value |= bits[i] << cursor

      cursor++
    }

    if (cursor > 0) {
      // flush the rest
      result += base64UrlSafeCharSet[value]
    }

    return result || ""
  }
}

export { GibConfiguration }
