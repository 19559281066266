import { PublicKey } from "@solana/web3.js"
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"

import toast from "react-hot-toast"
import { cancelTx, getDeposit } from "../../lib/requests"
import { Deposit } from "../../lib/types"
import { useSessionContext } from "./sessionContext"
import { useSourceContext } from "./sourceContext/sourceContext"
import {
  isConnectedSolExtensionSource,
  isConnectedSolPasskeySource,
  isSourceTypeSol,
} from "./sourceContext/types"

export enum DepositActions {
  CANCEL = "CANCEL",
  REVERT = "REVERT",
}

type SettingsContextType = {
  fetchHistory: () => void
  depositHistory: Deposit[]
  handleHistoryAction: (
    sessionPubkey: PublicKey,
    action: DepositActions
  ) => void
  settingsPageVisible: boolean
}

const SettingsContext = createContext<SettingsContextType | null>(null)

// SettingsProvider has two concerns.
// 1. Managing the connection to various sources
// 2. Cancelling/Reverting deposits owned by a source
const SettingsProvider: React.FC<{ children: React.ReactNode }> =
  function SettingsProvider({ children }) {
    const { currentRoute } = useSessionContext()
    const { sources } = useSourceContext()
    const [depositHistory, setDepositHistory] = useState<Deposit[]>([])
    const settingsPageVisible = currentRoute?.id === "settings"

    const fetchHistory = useCallback(async () => {
      try {
        Promise.all(
          sources.map((source) => {
            let depositFn = null
            if (isSourceTypeSol(source)) {
              depositFn = source.action.getDeposits(source.publicKey, [
                source.selectedToken,
              ])
            }
            if (depositFn) {
              return depositFn
                .then((deposits) => deposits)
                .catch(() => {
                  toast.error("Failed to fetch Solana deposits :(")
                  return []
                })
            } else {
              return Promise.resolve([])
            }
          })
        )
          .then((results) => {
            const deposits = results
              .flat()
              .filter(
                (deposit, index, self) =>
                  index ===
                  self.findIndex((dep) => dep.revertTs === deposit.revertTs)
              )
            setDepositHistory(deposits)
          })
          .catch((err) => {
            console.log(err)
            setDepositHistory([])
          })
      } catch {
        return Promise.resolve([])
      }
    }, [sources])

    useEffect(() => {
      fetchHistory()
    }, [fetchHistory])

    const handleHistoryAction = useCallback(
      async (sessionPubkey: PublicKey, action: DepositActions) => {
        let deposit = depositHistory.find(
          (deposit) => deposit.sessionPubkey === sessionPubkey
        )
        let senderSource = sources.find(
          (source) =>
            deposit &&
            source.publicKey?.toString() === deposit.sender.toString()
        )
        if (deposit && senderSource) {
          //TODO: Refactor this nested switch case
          if (isConnectedSolPasskeySource(senderSource)) {
            switch (action) {
              case DepositActions.CANCEL: {
                try {
                  const source = senderSource
                  let unsignedTx = await cancelTx(
                    deposit.sessionPubkey as PublicKey
                  )
                  let keypair = await source.action.getSolKeypair()
                  let signedTx = await source.action.signTx(unsignedTx, keypair)
                  return source.action
                    .sendTx(signedTx, true)
                    .then(() => {
                      source.action.refetchBalance(source.publicKey)
                    })
                    .then(() => fetchHistory())
                } catch (err) {
                  toast.error("Failed to cancel deposit :(")
                  return
                }
              }
              case DepositActions.REVERT: {
                // Gib BE automatically reverts expired TX's whenever queried
                return getDeposit(deposit.sessionPubkey as PublicKey)
                  .then(() => fetchHistory())
                  .catch((err) => {
                    toast.error("Failed to revert deposit :(")
                    console.log(err)
                    return
                  })
              }
            }
          } else if (isConnectedSolExtensionSource(senderSource)) {
            switch (action) {
              case DepositActions.CANCEL: {
                try {
                  const source = senderSource

                  let unsignedTx = await cancelTx(
                    deposit.sessionPubkey! as PublicKey
                  )
                  let signedTx = await source.action.signTx(unsignedTx)
                  return await source.action
                    .sendTx(signedTx, true)
                    .then(() => {
                      source.action.refetchBalance(source.publicKey)
                    })
                    .then(() => fetchHistory())
                } catch (err) {
                  toast.error("Failed to cancel deposit :(")
                  return
                }
              }
              case DepositActions.REVERT: {
                // Gib BE automatically reverts expired TX's whenever queried
                return getDeposit(deposit.sessionPubkey! as PublicKey)
                  .then(() => fetchHistory())
                  .catch((err) => {
                    toast.error("Failed to revert deposit :(")
                    console.log(err)
                  })
              }
            }
          } else {
            throw new Error(
              "SettingsContext has not implemented ActionHistory yet"
            )
          }
        }
      },
      [depositHistory, fetchHistory, sources]
    )

    return (
      <SettingsContext.Provider
        value={{
          fetchHistory,
          depositHistory,
          settingsPageVisible,
          handleHistoryAction,
        }}
      >
        {children}
      </SettingsContext.Provider>
    )
  }

function useSettingsContext(): SettingsContextType {
  const context = useContext(SettingsContext)

  if (!context) {
    throw new Error("Missing SettingsContext")
  }

  return context
}

export { SettingsProvider, useSettingsContext }
