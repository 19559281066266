import {
  Keypair,
  PublicKey,
  Transaction,
  VersionedTransaction,
} from "@solana/web3.js"
import { solUsdcToken } from "../../../lib/constants"
import { GibWallet, PayloadSchema } from "../../../lib/passkeys"
import { FullBalanceResponse } from "../../../lib/requests"
import { Deposit, Token } from "../../../lib/types"
import { SelectedChain } from "../../../lib/utils"
import { IconType } from "../../../sections/shared/SvgIcon"
import { ClaimBalanceFetch } from "../claimContext/types"

export enum SourceOrigin {
  SOL_WALLET_EXTENSION = "SOL_WALLET_EXTENSION",
  SOL_WALLET_PASSKEY = "SOL_WALLET_PASSKEY",
}

export interface SolExtensionActions {
  disconnect: () => Promise<void>
  refetchBalance: (pubkey: PublicKey) => void
  select: () => void
  signTx: (tx: Transaction) => Promise<Transaction>
  sendTx: (
    tx: Transaction | VersionedTransaction,
    withConfirmation?: boolean
  ) => Promise<string>
  getDeposits: (senderPubkey: PublicKey, tokens: Token[]) => Promise<Deposit[]>
  getDeposit: (
    sessionKeypair: Keypair,
    token: Token
  ) => Promise<ClaimBalanceFetch["payload"]>
}

export interface SolPasskeyActions {
  disconnect: () => Promise<void>
  refetchBalance: (pubkey: PublicKey) => void
  select: () => void
  getSolKeypair: () => Promise<Keypair>
  signTx: (tx: Transaction, keypair: Keypair) => Promise<Transaction>
  sendTx: (
    tx: Transaction | VersionedTransaction,
    withConfirmation?: boolean
  ) => Promise<string>
  getDeposits: (senderPubkey: PublicKey, tokens: Token[]) => Promise<Deposit[]>
  getDeposit: (
    sessionKeypair: Keypair,
    token: Token
  ) => Promise<ClaimBalanceFetch["payload"]>
  getAccountClosureTx: (
    accountAddr: PublicKey,
    tokenMint: PublicKey,
    recipient: PublicKey
  ) => Promise<Transaction>
  getFullAccountBalance: (
    accountAddr: PublicKey
  ) => Promise<FullBalanceResponse>
}

export interface DisconnectedActions {
  connect: () => Promise<void>
}

export interface DisconnectedPasskeyActions {
  signIn: (pubkeyMatches?: PublicKey) => Promise<GibWallet>
  signUp: () => Promise<PayloadSchema>
}

// Details on the wallet connected (e.g. Phantom/MetaMask)
export interface SourceMetaData {
  name: string
  logoUri: string | IconType
}

export type TokenBalance = {
  rawAmount: bigint
  formattedAmount: string
}

interface BaseSourceCommon {
  publicKey: PublicKey | null
  connected: boolean
  chain: SelectedChain
  chainIcon: IconType
  origin: SourceOrigin
  metaData: SourceMetaData | null
  selectedToken: Token
}

export type BaseSourceDisconnected = BaseSourceCommon & {
  publicKey: null
  connected: false
  metaData: SourceMetaData | null
  action: DisconnectedActions | DisconnectedPasskeyActions | null
}

export interface SolBaseSourceConnected {
  publicKey: PublicKey
  connected: true
  chainIcon: IconType
  chain: SelectedChain.SOLANA
  origin: SourceOrigin
  metaData: SourceMetaData
  tokenBalance: TokenBalance | null
  action: SolExtensionActions | SolPasskeyActions
  selectedToken: Token
}

export type BaseSource = BaseSourceDisconnected | SolBaseSourceConnected

export interface SolWalletExtensionSource extends SolBaseSourceConnected {
  publicKey: PublicKey
  chain: SelectedChain.SOLANA
  origin: SourceOrigin.SOL_WALLET_EXTENSION
  action: SolExtensionActions
  tokenBalance: TokenBalance | null
  selectedToken: Token
  metaData: SourceMetaData
}

export interface SolPasskeyExtensionSource extends SolBaseSourceConnected {
  publicKey: PublicKey
  chain: SelectedChain.SOLANA
  origin: SourceOrigin.SOL_WALLET_PASSKEY
  action: SolPasskeyActions
  tokenBalance: TokenBalance | null
  selectedToken: Token
  metaData: SourceMetaData
}

const solSources: BaseSource[] = [
  {
    publicKey: null,
    chain: SelectedChain.SOLANA,
    chainIcon: IconType.Solana,
    selectedToken: solUsdcToken,
    connected: false,
    origin: SourceOrigin.SOL_WALLET_PASSKEY,
    action: null,
    metaData: {
      name: "Gib Wallet",
      logoUri: IconType.GibCashGreen,
    },
  },
  {
    publicKey: null,
    chain: SelectedChain.SOLANA,
    chainIcon: IconType.Solana,
    selectedToken: solUsdcToken,
    connected: false,
    origin: SourceOrigin.SOL_WALLET_EXTENSION,
    action: null,
    metaData: {
      name: "Solana Wallet",
      logoUri: IconType.Solana,
    },
  },
]

export const defaultSources: BaseSource[] = solSources

export function isSolExtensionSource(
  source: BaseSource
): source is SolWalletExtensionSource {
  return source.origin === SourceOrigin.SOL_WALLET_EXTENSION
}

export function isConnectedSolExtensionSource(
  source: BaseSource
): source is SolWalletExtensionSource {
  return (
    source.origin === SourceOrigin.SOL_WALLET_EXTENSION &&
    source.connected &&
    source.publicKey instanceof PublicKey
  )
}

export function isSolPasskeySource(
  source: BaseSource
): source is SolPasskeyExtensionSource {
  return source.origin === SourceOrigin.SOL_WALLET_PASSKEY
}

export function isConnectedSolPasskeySource(
  source: BaseSource
): source is SolPasskeyExtensionSource {
  return (
    source.origin === SourceOrigin.SOL_WALLET_PASSKEY &&
    source.connected &&
    source.publicKey instanceof PublicKey
  )
}

export function isSourceTypeSol(
  source: BaseSource | null
): source is SolPasskeyExtensionSource | SolWalletExtensionSource {
  if (source) {
    return (
      isConnectedSolExtensionSource(source) ||
      isConnectedSolPasskeySource(source)
    )
  } else {
    return false
  }
}

export function isConnectedSource(
  source: BaseSource | null | undefined
): source is SolBaseSourceConnected {
  if (source) {
    return (
      isSourceTypeSol(source) &&
      source.action !== null &&
      source.action !== undefined
    )
  } else {
    return false
  }
}

export function isDisconnectedSource(
  source: BaseSource | null | undefined
): source is BaseSourceDisconnected {
  if (source) {
    return source.connected === false && source.publicKey === null
  } else {
    return false
  }
}
export function hasDisconnectedWalletActions(
  action: DisconnectedActions | DisconnectedPasskeyActions | null
): action is DisconnectedActions {
  return action !== null && "connect" in action
}

export function hasDisconnectedPasskeyActions(
  action: DisconnectedActions | DisconnectedPasskeyActions | null
): action is DisconnectedPasskeyActions {
  return action !== null && "signIn" in action && "signUp" in action
}
