import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"

interface ScrollContextValue {
  isScrolled: boolean
}

const ScrollContext = createContext<ScrollContextValue | undefined>(undefined)

export const ScrollProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isScrolled, setIsScrolled] = useState(false)
  const isMobile = window.innerWidth < 600 // we only need this scroll state on mobile, not on big screens
  const scrollDiv = useRef<HTMLDivElement | null>(null)

  const handleScroll = useCallback(() => {
    const currentScrollY = scrollDiv.current?.scrollTop || 0

    if (currentScrollY > 5 && isMobile) {
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }, [isMobile])

  useEffect(() => {
    const currentScrollDiv = scrollDiv.current
    if (currentScrollDiv) {
      currentScrollDiv.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (currentScrollDiv) {
        currentScrollDiv.removeEventListener("scroll", handleScroll)
      }
    }
  }, [handleScroll])

  return (
    <ScrollContext.Provider value={{ isScrolled }}>
      <div ref={scrollDiv} style={{ overflowY: "scroll", maxHeight: "100vh" }}>
        {children}
      </div>
    </ScrollContext.Provider>
  )
}

export const useScrollContext = (): ScrollContextValue => {
  const context = useContext(ScrollContext)
  if (!context) {
    throw new Error("useScrollContext must be used within a ScrollProvider")
  }
  return context
}
