import {
  ClaimActionKind,
  ClaimActions,
  ClaimProviderState,
  ClaimState,
  ClaimVisualState,
  claimProviderDefaultState,
} from "./types"

export function claimReducer(
  state: ClaimProviderState,
  action: ClaimActions
): ClaimProviderState {
  switch (action.type) {
    case ClaimActionKind.CLAIM_BALANCE_FETCH: {
      return {
        ...state,
        ...action.payload,
        verified: state.verified || action.payload.verified, // dont overwrite already verified state
        claimState: ClaimState.BALANCE_LOADED,
      }
    }
    case ClaimActionKind.CLAIM_TX_INIT: {
      return {
        ...state,
        ...action.payload,
        claimState: ClaimState.TX_CREATED,
      }
    }
    case ClaimActionKind.CLAIM_TX_VERIFY: {
      return {
        ...state,
        ...action.payload,
        visualState: ClaimVisualState.START,
      }
    }
    case ClaimActionKind.CLAIM_TX_SEND: {
      return {
        ...state,
        ...action.payload,
        claimState: ClaimState.TX_SENT,
        visualState: ClaimVisualState.SUCCESS,
      }
    }
    case ClaimActionKind.CLAIM_TX_ERROR: {
      let visualState = state.visualState
      if (action.payload.error.endsWith("not match recipient username")) {
        visualState = ClaimVisualState.WRONG_SIGNATURE
      }
      return {
        ...state,
        ...action.payload,
        claimState: ClaimState.TX_ERROR,
        visualState: visualState,
      }
    }
    case ClaimActionKind.TOO_SLOW: {
      return {
        ...state,
        claimState: ClaimState.TOO_SLOW,
        visualState: ClaimVisualState.CLAIMED,
      }
    }
    case ClaimActionKind.VISUAL_TRANSITION: {
      return {
        ...state,
        visualState: action.payload.visualState,
      }
    }
    case ClaimActionKind.RESET: {
      return { ...claimProviderDefaultState }
    }
  }
}
