import { GibConfiguration } from "./config"
import {
  SocialAccount,
  SocialPlatform,
  SocialPrefix,
  TimeInSeconds,
} from "./types"

export const stripSuffixSol = (str: string) => {
  return str.split(".sol")[0]
}

export function truncatePk(pk: string): string {
  return `${pk.slice(0, 5)}...${pk.slice(-3)}`
}

export enum RefundTime {
  TWO_MINUTES = "Two Minutes",
  FIVE_MINUTES = "Five Minutes",
  ONE_DAY = "One Day",
  THREE_DAYS = "Three Days",
  ONE_WEEK = "One Week",
}

export function refundTimeToEnum(time: RefundTime): string {
  switch (time) {
    case RefundTime.TWO_MINUTES:
      return "TWO_MINUTE"
    case RefundTime.FIVE_MINUTES:
      return "FIVE_MINUTE"
    case RefundTime.ONE_DAY:
      return "ONE_DAY"
    case RefundTime.THREE_DAYS:
      return "THREE_DAYS"
    case RefundTime.ONE_WEEK:
      return "SEVEN_DAYS"
  }
}

// our encoding approach is basically base64 (with url-safe charset),
// but on the bit level. Any standard base64 library would not be able to handle
// a single-character string, because a single character is less than a byte.
export const base64UrlSafeCharSet =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_"

export interface DappConfiguration {
  /**
   * Indicates whether the Dapp is password protected.
   */
  isPasswordProtected: boolean
  /**
   * Represents the selected blockchain chain for the Dapp.
   */
  chain: SelectedChain
  /**
   * Social platform configuration relevant if the deposit item returns a `social_id` hash.
   *
   * @remarks This property is inherently optional, but due to the compressed
   *   nature of the encoding used for the configuration, an option can not be
   *   marked explicitly unless spending a valuable bit position. The means in
   *   case of a "zero" bits value, the setting default to the first variant of
   *   `SocialPlatform`.
   */
  social: SocialPlatform
  /**
   * Indicates whether the schema of the Dapp needs to be updated.
   */
  updateSchema: boolean
}

export enum SelectedChain {
  SOLANA = 0,
}

export const DEFAULT_DAPP_CONFIG: GibConfiguration = GibConfiguration.default()

export function initializeDappConfig(
  isPasswordProtected: boolean,
  social?: SocialPlatform
): GibConfiguration {
  const chain = SelectedChain.SOLANA
  return GibConfiguration.fromConfig({
    isPasswordProtected: !!isPasswordProtected,
    chain,
    social: social || SocialPlatform.TELEGRAM,
    updateSchema: false,
  })
}

export function encodeWithConfig(
  intArray: number[],
  config?: GibConfiguration,
  passwordCheck?: Uint8Array
): string {
  return (
    (config || DEFAULT_DAPP_CONFIG).serialize() +
    encode(intArray) +
    (passwordCheck
      ? encodeBytes(passwordCheck!)
          .map((n) => base64UrlSafeCharSet[n])
          .join("")
      : "")
  )
}

export function encode(intArray: number[]): string {
  let binaryStr = ""
  for (let num of intArray) {
    // Convert number to binary and pad it to be 11 bits long
    // bip-39 wordlist contains 1626 words (word be represented as 2^11 bits)
    binaryStr += num.toString(2).padStart(11, "0")
  }
  let output = ""
  for (let i = 0; i < binaryStr.length; i += 6) {
    // Get a chunk of 6 bits (or whatever remains at the end)
    let chunk = binaryStr.slice(i, i + 6)
    output += base64UrlSafeCharSet[parseInt(chunk, 2)]
  }
  return output
}

export function decodeWithConfig(input: string): {
  seedWordIndexArr: number[]
  config: DappConfiguration
  passwordCheck: Uint8Array | null
} {
  let passwordCheck = null
  if (input.length === 0) {
    return {
      seedWordIndexArr: [],
      config: DEFAULT_DAPP_CONFIG,
      passwordCheck,
    }
  }
  const config = GibConfiguration.deserialize(input.substring(0, 1))
  const decodedStringArr = decode(input.slice(1, 23))

  if (config.isPasswordProtected) {
    passwordCheck = decodeBytes(
      input
        .slice(23, 27)
        .split("")
        .map((char) => base64UrlSafeCharSet.indexOf(char))
    )
  }
  return {
    seedWordIndexArr: decodedStringArr,
    config: config,
    passwordCheck: passwordCheck,
  }
}

export function decode(input: string): number[] {
  let output: number[] = []
  let binaryStr = ""
  for (let char of input) {
    let num = base64UrlSafeCharSet.indexOf(char)
    // Convert number to binary and pad it to be 6 bits long
    let bin = num.toString(2).padStart(6, "0")
    binaryStr += bin
  }
  for (let i = 0; i < binaryStr.length; i += 11) {
    // Get a chunk of 11 bits (or whatever remains at the end)
    let chunk = binaryStr.slice(i, i + 11)
    output.push(parseInt(chunk, 2))
  }
  return output
}

// convert 3 bytes to four 6-bit numbers (our base64 indexes)
export function encodeBytes(arr: Uint8Array): number[] {
  if (arr.length !== 3) {
    throw new Error("Expected a 3-byte long Uint8Array.")
  }
  const joined = (arr[0] << 16) | (arr[1] << 8) | arr[2]
  return [
    (joined >> 18) & 0x3f,
    (joined >> 12) & 0x3f,
    (joined >> 6) & 0x3f,
    joined & 0x3f,
  ]
}

// convert four 6-bit numbers (our base64 indexes) to 3 bytes
export function decodeBytes(arr: number[]): Uint8Array {
  if (arr.length !== 4) {
    throw new Error("Expected an array with four 6-bit numbers.")
  }
  const [a, b, c, d] = arr
  const joined = (a << 18) | (b << 12) | (c << 6) | d
  return new Uint8Array([
    (joined >> 16) & 0xff,
    (joined >> 8) & 0xff,
    joined & 0xff,
  ])
}

export function timeSince(date: number): string {
  var seconds = Math.floor((Date.now() - date) / 1000)

  var interval = seconds / 31536000

  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + " days"
  }
  interval = seconds / 3600
  if (interval > 2) {
    return Math.floor(interval) + " hours"
  } else {
    return " < 1 hour"
  }
}

export const amountMultiplier = (decimal: number): number =>
  Math.pow(10, decimal)

function truncateToDecimals(number: number, decimals: number): number {
  const factor = Math.pow(10, decimals)
  return Math.floor(number * factor) / factor
}

export const formatAmount = (amount: number, decimals: number): string => {
  return truncateToDecimals(
    Number(amount) / Math.pow(10, decimals),
    2
  ).toString()
}

export const expiryToDateStringDe = (expiry: TimeInSeconds): string => {
  const date = new Date(expiry * 1000)
  const formattedDate = date.toLocaleString("de", {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  })

  return formattedDate
}

export function Sleep(milliseconds: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, milliseconds))
}

export const socialShort = (platform: SocialPlatform): SocialPrefix => {
  switch (platform) {
    case SocialPlatform.TELEGRAM: {
      return "tg"
    }
    case SocialPlatform.X: {
      return "x"
    }
  }
}

export const socialString = (account: SocialAccount) => {
  const prefix = socialShort(account.platform)
  return `${prefix}:${account.handle.toLocaleLowerCase()}`
}

export const platformName = (platform: SocialPlatform) => {
  return platform.toString().toLowerCase()
}

export const toHex = (uint8Array: Uint8Array): string => {
  return Array.from(uint8Array, (byte) =>
    ("0" + (byte & 0xff).toString(16)).slice(-2)
  ).join("")
}
