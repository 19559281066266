import * as Sentry from "@sentry/react"
import {
  PublicKey,
  Signer,
  Transaction,
  VersionedTransaction,
} from "@solana/web3.js"
import nacl from "tweetnacl"
import { GIB_URL } from "./constants"
import {
  ClaimResponse,
  DepositResponse,
  GibData,
  SocialAccount,
  TelegramAppData,
  VerifiedTelegramData,
  oAuthResponseData,
} from "./types"
import { socialString } from "./utils"

global.Buffer = require("buffer").Buffer

const fetchWithRetry = async (
  url: string,
  options: RequestInit,
  retryCount = 3,
  delay = 1000
): Promise<Response> => {
  let attempts = 0
  while (attempts <= retryCount) {
    const response = await fetch(url, options)

    if (response.ok) {
      return response
    }
    if (response.status > 499) {
      attempts++
      if (attempts === retryCount) {
        Sentry.captureException(
          new Error(`HTTP${response.status}:${url} - ${await response.text()}`)
        )
      }
      await new Promise((resolve) => setTimeout(resolve, delay))
    } else {
      return Promise.reject(await response.text())
    }
  }

  return Promise.reject("Max retries reached")
}

export type SocialClaimParams = {
  code: string
  linkOwnershipVerifier: number[]
}

export type RegularClaimParams = {
  receiver: PublicKey
}

export type ClaimParams = RegularClaimParams | SocialClaimParams

export type FullBalanceResponse = {
  total_balance_usd: string
}
export const getFullAccountBalance = async (
  accountAddr: PublicKey
): Promise<FullBalanceResponse> => {
  const response = await fetchWithRetry(
    `${GIB_URL}/account/balance/${accountAddr.toString()}`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    },
    1
  )
  return response.json() as Promise<FullBalanceResponse>
}

export const getDeposit = async (linkPubkey: PublicKey): Promise<GibData> => {
  const response = await fetchWithRetry(
    `${GIB_URL}/gib/${linkPubkey.toString()}`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    }
  )
  return response.json() as Promise<GibData>
}

export const getDeposits = async (
  senderPubkey: PublicKey
): Promise<DepositResponse[]> => {
  const response = await fetchWithRetry(
    `${GIB_URL}/gib/deposits/${senderPubkey.toString()}`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    }
  )
  return response.json() as Promise<DepositResponse[]>
}

export async function revertDeposit(linkPubkey: PublicKey): Promise<string> {
  const response = await fetchWithRetry(
    `${GIB_URL}/gib_revert/${linkPubkey.toBase58()}`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    }
  )

  return response.text()
}

export const sendTransaction = async (
  transaction: Transaction | VersionedTransaction
): Promise<string> => {
  let serializedTx: string | null = null
  if (transaction instanceof VersionedTransaction) {
    const wireTransaction = transaction.serialize()
    const str = String.fromCharCode(...new Uint8Array(wireTransaction))
    serializedTx = btoa(str)
  } else if (transaction instanceof Transaction) {
    const wireTransaction = transaction.serialize({
      requireAllSignatures: true,
      verifySignatures: false,
    })
    serializedTx = wireTransaction.toString("base64")
  } else {
    return Promise.reject("unknown TX type")
  }
  if (serializedTx) {
    const response = await fetchWithRetry(`${GIB_URL}/gib/send`, {
      method: "POST",
      body: JSON.stringify({ serialized_tx: serializedTx }),
      headers: {
        "Content-type": "application/json",
      },
    })
    return response.text()
  } else {
    return Promise.reject("failed to serialize TX")
  }
}

export const getVerificationMessage = async (
  linkPubkey: PublicKey,
  recipient: PublicKey
): Promise<number[]> => {
  const response = await fetchWithRetry(
    `${GIB_URL}/verification_message/${linkPubkey.toBase58()}`,
    {
      method: "POST",
      body: recipient.toBase58(),
      headers: { "Content-type": "application/json" },
    }
  )

  return (await response.json()) as number[]
}

export const signVerificationMessage = (
  signer: Signer,
  message: number[]
): number[] => {
  const msgBytes = new Uint8Array(message)
  const signature = nacl.sign.detached(msgBytes, signer.secretKey)
  const verify = nacl.sign.detached.verify(
    msgBytes,
    signature,
    signer.publicKey.toBytes()
  )
  if (!verify) {
    throw new Error("unable to verify link secret signature")
  }

  return Buffer.from(signature).toJSON().data
}

export const deserializeTx = (serializedTx: string): Transaction => {
  return Transaction.from(Buffer.from(serializedTx, "base64"))
}

export const depositTx = async (
  sender: PublicKey,
  linkPubkey: PublicKey,
  escrowToken: PublicKey,
  revertTs: number,
  amount: BigInt,
  socialId?: string
): Promise<Transaction> => {
  let response = await fetchWithRetry(`${GIB_URL}/gib_init`, {
    method: "POST",
    body: JSON.stringify({
      revert_ts: revertTs,
      amount: Number(amount),
      social_id: socialId,
      escrow_token: escrowToken.toBuffer().toJSON().data,
      sender_pubkey: sender.toBuffer().toJSON().data,
      link_pubkey: linkPubkey.toBuffer().toJSON().data,
    }),
    headers: {
      "Content-type": "application/json",
    },
  })
  return deserializeTx(await response.text())
}

export const claimTx = async (
  linkPubkey: PublicKey,
  claimParams: ClaimParams
): Promise<ClaimResponse> => {
  let body: string | null = null

  if ("receiver" in claimParams) {
    const { receiver } = claimParams
    body = JSON.stringify({
      recipient: receiver.toBuffer().toJSON().data,
    })
  } else if ("linkOwnershipVerifier" in claimParams) {
    const { linkOwnershipVerifier, code } = claimParams
    body = JSON.stringify({
      link_own_verif: linkOwnershipVerifier,
      code: code?.toString(),
    })
  }

  let response = await fetchWithRetry(
    `${GIB_URL}/gib_claim/${linkPubkey.toBase58()}`,
    {
      method: "POST",
      body,
      headers: {
        "Content-type": "application/json",
      },
    }
  )

  return (await response.json()) as ClaimResponse
}

export const cancelTx = async (linkPubkey: PublicKey): Promise<Transaction> => {
  let response = await fetchWithRetry(
    `${GIB_URL}/gib_cancel/${linkPubkey.toBase58()}`,
    {
      method: "GET",
      headers: {
        "Content-type": "application/json",
      },
    }
  )
  return deserializeTx(await response.text()) as Transaction
}

export const createSocialhash = async (
  account: SocialAccount
): Promise<string> => {
  const uInt8Arr = new TextEncoder().encode(socialString(account))
  const hashBuffer = await window.crypto.subtle.digest("SHA-256", uInt8Arr)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("")

  return Promise.resolve(hashHex)
}

export const getCloseAccountTx = async (
  accountOwnerPubkey: PublicKey,
  tokenMint: PublicKey,
  recipient: PublicKey
): Promise<Transaction> => {
  return fetch(`${GIB_URL}/gib/close`, {
    method: "POST",
    body: JSON.stringify({
      account_to_close: accountOwnerPubkey.toBuffer().toJSON().data,
      token_mint: tokenMint.toBuffer().toJSON().data,
      recipient: recipient.toBuffer().toJSON().data,
    }),
    headers: { "Content-type": "application/json" },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText)
      }
      return response.text()
    })
    .then((serializedTx) => deserializeTx(serializedTx))
    .catch((error) => {
      throw new Error("Failed to deserialize Solana Transaction")
    })
}

export async function postVerification(
  depositAddress: string,
  recipientAddress: string
): Promise<number> {
  const requestBody = {
    deposit_id: depositAddress,
    recipient: recipientAddress,
  }

  return fetchWithRetry(`${GIB_URL}/verification`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(requestBody),
  }).then((response) => response.json() as Promise<number>)
}

export async function getVerification(code: number): Promise<string> {
  return await fetchWithRetry(`${GIB_URL}/verification/${code}`, {}).then(
    (response) => response.text()
  )
}

export const verifyTelegram = async (
  data: TelegramAppData
): Promise<VerifiedTelegramData> => {
  try {
    const response = await fetchWithRetry(`${GIB_URL}/tg_verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: data.dataCheckString,
        hash: data.hash,
      }),
    })

    const json = await response.json()
    return json as VerifiedTelegramData
  } catch (e) {
    throw new Error(`${e instanceof Error ? e.message : e}`)
  }
}

export const fetchOAuthVerify = async <T>(
  url: string,
  params: {
    code: string
    oauthToken: string
    oauthTokenSecret: string
    oauthVerifier: string
  }
): Promise<T> => {
  try {
    const response = await fetchWithRetry(url, {
      method: "POST",
      body: JSON.stringify({
        code: params.code,
        oauth_token: params.oauthToken,
        oauth_token_secret: params.oauthTokenSecret,
        oauth_verifier: params.oauthVerifier,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })

    const json = await response.json()
    return json as T
  } catch (e) {
    throw new Error(`${e instanceof Error ? e.message : e}`)
  }
}

export const createOAuthToken = async (
  callbackUrl: string
): Promise<oAuthResponseData> => {
  const response = await fetchWithRetry(
    `${GIB_URL}/x_request_token?callback=${callbackUrl}`,
    {
      method: "POST",
      headers: {
        "Content-type": "application/json",
      },
    }
  )
  return response.json() as Promise<oAuthResponseData>
}
