import { Grid } from "@mui/material"

import { useRoutes } from "react-router-dom"
import { CSSTransition, SwitchTransition } from "react-transition-group"
import { useSessionContext } from "../../context/v2/sessionContext"
import { routes } from "./routes"

const RoutesWrapper = () => {
  const currentRouteComponent = useRoutes(routes)
  const { currentRoute } = useSessionContext()

  return (
    <Grid
      item
      flex={1}
      height={"100%"}
      width={"100%"}
      style={{
        marginTop: "72px",
        flex: "1 1 auto",
      }}
    >
      <SwitchTransition>
        <CSSTransition
          key={currentRoute?.id}
          classNames="fade"
          timeout={300}
          unmountOnExit
        >
          {currentRouteComponent}
        </CSSTransition>
      </SwitchTransition>
    </Grid>
  )
}

export default RoutesWrapper
